import { all, takeEvery, put, fork, call, delay} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { push } from 'connected-react-router'
import { Plugins } from '@capacitor/core';
import { getAzConfigSettings } from '../util/az-tools';

//import { getToken, clearToken, getAuthToken, clearAccessToken } from 'library/helpers/utility';
import {REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, API_DIAGNOSTIC,
        LOGIN_SUCCESS,LOGIN_FAILURE, LOGOUT_REQUEST,
        UPDATE_TOKEN, PORTAL_REQUEST, PORTAL_SUCCESS, PORTAL_FAILURE,
        RESETPW_REQUEST,RESETPW_SUCCESS,RESETPW_FAILURE,
        RESETPW_CONFIRM_REQUEST,RESETPW_CONFIRM_SUCCESS,RESETPW_CONFIRM_FAILURE,
        CHANGEPW_REQUEST, CHANGEPW_SUCCESS, CHANGEPW_FAILURE,
        UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE, USER_VERIFICATION_REQUEST, USER_VERIFICATION_SUCCESS, USER_VERIFICATION_FAILURE } from '../actions/auth';
import {fetchUser, fetchPortal, createUser, resetPasswordConfirm, resetPassword, changePassword, updateUser, verifyUser } from './api';
const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

const { Storage } = Plugins;

export async function getTokenObject() {
  const ret = await Storage.get({ key: 'token' });
  const token = ret.value;
  return token
}

export async function getUserObject() {
  const ret = await Storage.get({ key: 'user' });
  const user = JSON.parse(ret.value);
  return user
}

async function setUserObject(userPayload) {
  await Storage.set({
    key: 'user',
    value: JSON.stringify(userPayload)
  });
}

async function setTokenObject(token) {
  await Storage.set({
    key: 'token',
    value: token
  });
}

async function resetAuth() {
  await Storage.remove({ key: 'user' });
  await Storage.remove({ key: 'token' });

}

async function getAzConfig() {
  const azConfig = await getAzConfigSettings();
  return azConfig;

}



function isNotEmpty(obj) {
    return Object.keys(obj).length > 1;
}

export function* apiDiagnostic() {
  yield takeEvery(API_DIAGNOSTIC, function*() {

    const azConfig = yield call(() =>{
      return getAzConfig();
    });

    if(azConfig["STAGE"] != "live"){

      yield put({
        type: '@@app/ALERTMESSAGE',
        payload: `ML Diagnostic: STAGE ==> ${azConfig["STAGE"]} -- Current Test: API ValidationError Object`,
      });

      delay(5000)

      yield put({
          type: '@@app/CLOSE_ALERT'
        });
    }
  });
}

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    const { username, password } = payload;
    //console.log(payload)

    const authToken = '';
    const user = {};

    try {
       const data = yield call(() => {
          return fetchUser(username, password)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: LOGIN_SUCCESS,
         token: data.token,
         user: data.user,
       });
       yield put({
         type: "@@GET_ME_SUCCESS",
         payload: {user: data.user, accessToken: data.token, isVerified: data.is_verified, isLoggedIn: true },
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {  
       console.log("ML TEST: loginRequest()  ErrorDetail Obj ==> ", error);
       yield put({type: "FETCH_FAILED", error})
       yield put({ type: LOGIN_FAILURE, error });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    }
  });
}

export function* loginSuccess() {
  yield takeEvery(LOGIN_SUCCESS, function*(payload) {
    yield setTokenObject(payload.token)
    yield setUserObject(payload.user)

    // yield localStorage.setItem('token', payload.token);
    // yield localStorage.setItem('clientID', uuidv4());
    // yield localStorage.setItem('user', JSON.stringify(payload.user));

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* loginError() {
  yield takeEvery(LOGIN_FAILURE, function*(error) {
    var errorMsg = ''

    if(error.error && error.error.body){
      //console.log(error)

      if(error.error.body && error.error.body.non_field_errors){
        if(error.error.body.non_field_errors[0] === 'Unable to log in with provided credentials.'){
          errorMsg = 'That username / password combination did not work. Please try again.'
        }else{
          errorMsg = error.error.body.non_field_errors[0]
        }
      }
      else if(error.error.body.password){
        errorMsg = 'Your password may not be blank.'
      }
      else if(error.error.body.username){
        errorMsg = 'Your username may not be blank.'
      }
      yield put({
        type: '@@app/ALERTMESSAGE',
        payload: 'Error: '+errorMsg,
      });

      delay(4000)

      yield put({
        type: '@@app/CLOSE_ALERT'
      });

    }else{

      errorMsg = error;
      yield put({
        type: '@@app/ALERTMESSAGE',
        payload: 'Sorry ... an unknown error occured: '+errorMsg,
      });

      delay(4000)

      yield put({
        type: '@@app/CLOSE_ALERT'
      });
      
    }

  });
}

export function* logout() {
  yield takeEvery(LOGOUT_REQUEST, function*() {
    //yield clearToken();
    yield resetAuth()
    // localStorage.removeItem('access_token');
    // localStorage.removeItem('expires_at');
    // localStorage.removeItem('test');
    history.push('/login');
  });
}


export function* userVerificationRequest() {
  yield takeEvery('USER_VERIFICATION_REQUEST', function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    const { email, verify_key } = payload;

    try {
       const data = yield call(() => {
          return verifyUser(email, verify_key)
        });
       yield put({type: "USER_VERIFICATION_SUCCEEDED", data})
       yield put({
         type: USER_VERIFICATION_SUCCESS,
         email: data.email,
         isUserVerified: data.isUserVerified,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {  
       console.log("ML TEST: userVerificationRequest()  ErrorDetail Obj ==> ", error);
       yield put({type: "FETCH_FAILED", error})
       yield put({type: USER_VERIFICATION_FAILURE, error });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    }
  });
}

export function* userVerficationSuccess() {
  yield takeEvery(USER_VERIFICATION_SUCCESS, function*(payload) {
    //yield setTokenObject(payload.token)
    //yield setUserObject(payload.user)

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}

export function* userVerificationError() {
  yield takeEvery(USER_VERIFICATION_FAILURE, function*(error) {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'Sorry ... an error occurred during email verification. Try again.',
    });

    delay(4000)

    yield put({
      type: '@@app/CLOSE_ALERT'
    });
  });
}

export function* portalRequest() {
  yield takeEvery(PORTAL_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    //console.log(payload)
    try {
       const data = yield call(() => {
          //console.log(payload)
          return fetchPortal(payload)
        });
       //yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: PORTAL_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       // yield put({
       //   type: '@@app/ALERTMESSAGE',
       //   payload: "Redirecting to billing portal...",
       // });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: PORTAL_FAILURE });

    }
  });
}

export function* portalSuccess() {
  yield takeEvery(PORTAL_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* portalError() {
  yield takeEvery(PORTAL_FAILURE, function*(error) {

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: false,
    });


  });
}

export function* signupRequest() {
  yield takeEvery(REGISTER_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return createUser(payload)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: REGISTER_SUCCESS,
         payload: data,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {
       yield put({type: "FETCH_FAILED", error})
       yield put({ type: REGISTER_FAILURE, error });

    }
  });
}

export function* signupSuccess() {
  yield takeEvery(REGISTER_SUCCESS, function*(payload) {
    yield localStorage.setItem('token', payload.token);
    yield localStorage.setItem('clientID', uuidv4());

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'Congrats! You have signed up sucessfully.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    yield put(push('/'))


    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* signupError() {
  yield takeEvery(REGISTER_FAILURE, function*(error) {

    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: false,
    });

    var errorMsg = ''
    if(isNotEmpty(error.error.body)){
      errorMsg = 'You are missing signup fields, a unique non-taken email, or minimum of 8 password characters.'
    }
    else if(error.error.body.password2){
      errorMsg = 'Your confirm password may not be blank.'
    }
    else if(error.error.body.username){
      errorMsg = 'Your username may not be blank.'
    }
    else if(error.error.body.password1){
      errorMsg = 'Your password may not be blank.'
    }
    else if(error.error.body.email){
      errorMsg = error.error.body.email
    }
    else if(error.error.body.first_name){
      errorMsg = 'Your first name may not be blank.'
    }
    else if(error.error.body.last_name){
      errorMsg = 'Your last_name may not be blank.'
    }
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'Error: '+errorMsg,
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

  });
}

export function* resetPasswordRequest() {
  yield takeEvery(RESETPW_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return resetPassword(payload)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: RESETPW_SUCCESS,
         payload: data,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: RESETPW_FAILURE, error });

    }
  });
}

export function* resetPasswordSuccess() {
  yield takeEvery(RESETPW_SUCCESS, function*(payload) {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'An email has been sent to reset your password if you are a user.',
    });

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* resetPasswordError() {
  yield takeEvery(RESETPW_FAILURE, function*(error) {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'You are missing the email field...',
    });

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}

export function* resetPasswordConfirmRequest() {
  yield takeEvery(RESETPW_CONFIRM_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return resetPasswordConfirm(payload)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: RESETPW_CONFIRM_SUCCESS,
         payload: data,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: RESETPW_CONFIRM_FAILURE, error });

    }
  });
}

export function* resetPasswordConfirmSuccess() {
  yield takeEvery(RESETPW_CONFIRM_SUCCESS, function*(payload) {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'Congrats! You have reset your password sucessfully.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* resetPasswordConfirmError() {
  yield takeEvery(RESETPW_CONFIRM_FAILURE, function*(error) {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'You are missing password fields, or minimum of 8 password characters.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}

export function* changePasswordRequest() {
  yield takeEvery(CHANGEPW_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return changePassword(payload)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: CHANGEPW_SUCCESS,
         payload: data,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: CHANGEPW_FAILURE, error });

    }
  });
}

export function* changePasswordSuccess() {
  yield takeEvery(CHANGEPW_SUCCESS, function*(payload) {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'Congrats! You have changed your password sucessfully.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* changePasswordError() {
  yield takeEvery(CHANGEPW_FAILURE, function*(error) {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'You are missing password fields, or minimum of 8 password characters.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}

export function* updateUserRequest() {
  yield takeEvery(UPDATE_USER_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return updateUser(payload)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: UPDATE_USER_SUCCESS,
         payload: data,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: UPDATE_USER_FAILURE, error });

    }
  });
}

export function* updateUserSuccess() {
  yield takeEvery(UPDATE_USER_SUCCESS, function*(payload) {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'Congrats! You have changed your profile sucessfully.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* updateUserError() {
  yield takeEvery(UPDATE_USER_FAILURE, function*(error) {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: 'You are missing profile update fields, or another error has occured.',
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}
//Check Authorization token
// export function* checkAuthorization() {
//   yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
//     const token = getToken().get('idToken');
//     if (token) {
//       yield put({
//         type: actions.LOGIN_SUCCESS,
//         token, test,
//         profile: 'Profile',
//       });
//     }
//   });
// }

// export function* loginWorker(action) {
//    let url = action.payload.url
//    try {
//       const data = yield call(Api.fetchUser, url)
//       yield put({type: "FETCH_SUCCEEDED", data})
//    } catch (error) {
//       yield put({type: "FETCH_FAILED", error})
//    }
// }

// export function* fetchData(action) {
//    let url = action.payload.url
//    try {
//       const data = yield call(Api.fetchUser, url)
//       yield put({type: "FETCH_SUCCEEDED", data})
//    } catch (error) {
//       yield put({type: "FETCH_FAILED", error})
//    }
// }

export default function* authSaga() {
  yield all([
    //fork(checkAuthorization),
    fork(apiDiagnostic),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(signupRequest),
    fork(signupSuccess),
    fork(signupError),
    fork(updateUserRequest),
    fork(updateUserSuccess),
    fork(updateUserError),
    fork(resetPasswordRequest),
    fork(resetPasswordSuccess),
    fork(resetPasswordError),
    fork(resetPasswordConfirmRequest),
    fork(resetPasswordConfirmSuccess),
    fork(resetPasswordConfirmError),
    fork(changePasswordRequest),
    fork(changePasswordSuccess),
    fork(changePasswordError),
    fork(portalRequest),
    fork(portalSuccess),
    fork(portalError),
    //fork(registerRequest),
    fork(logout),
  ]);
}
