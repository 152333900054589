import { all, takeEvery, put, fork, call, delay} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { ToastProvider, useToast } from "@agney/ir-toast";
import { useHistory } from "react-router-dom";


//import { getToken, clearToken, getAuthToken, clearAccessToken } from 'library/helpers/utility';
import {START_AUDITQUEUE_REQUEST, START_AUDITQUEUE_SUCCESS, START_AUDITQUEUE_FAILURE, SUPPORT_REQUEST, SUPPORT_SUCCESS, SUPPORT_FAILURE,AUDIT_REPORT_REQUEST, AUDIT_REPORT_SUCCESS, AUDIT_REPORT_FAILURE,
  UPDATE_AUDIT_SUCCESS,UPDATE_AUDIT_FAILURE,UPDATE_AUDIT_REQUEST,UPDATE_MAIN_AUDIT_REQUEST, UPDATE_MAIN_AUDIT_SUCCESS, UPDATE_MAIN_AUDIT_FAILURE,
  CREATE_AUDIT_SUCCESS,CREATE_AUDIT_FAILURE,CREATE_AUDIT_REQUEST,GET_TYPE_REQUEST, GET_TYPE_SUCCESS, GET_TYPE_FAILURE, GET_REQUEST,
  GET_FAILURE, GET_SUCCESS, GET_DASHBOARD_REQUEST,GET_DASHBOARD_FAILURE, GET_DASHBOARD_SUCCESS, PLAN_REQUEST, PLAN_REQUEST_FAILURE, PLAN_REQUEST_SUCCESS} from '../actions/audits';
import {createUserSubscription, fetchAudits, fetchAuditQueueDetail, fetchAuditDetail, fetchDashboard, fetchAuditTypes, createAudit, updateAudit, updateMainAudit, createReport, createSupportTicket} from './api';

export function* auditRequest() {
  yield takeEvery(GET_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return fetchAudits()
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: GET_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: GET_FAILURE });

    }
  });
}



export function* auditSuccess() {
  yield takeEvery(GET_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* auditError() {
  yield takeEvery(GET_FAILURE, function*() {});
}

export function* planSelectionRequest() {
  yield takeEvery(PLAN_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
      const data = yield call(() => {
         return createUserSubscription(payload.planId)
       });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: PLAN_REQUEST_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: PLAN_REQUEST_FAILURE });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    }
  });
}



export function* planSelectionSuccess() {
  yield takeEvery(PLAN_REQUEST_SUCCESS, function*(payload) {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Redirecting to billing checkout portal...",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* planSelectionError() {
  yield takeEvery(PLAN_REQUEST_FAILURE, function*() {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Could not create a new subscription. Please contact us, or try again later.",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}

export function* auditQueueRequest() {
  yield takeEvery(START_AUDITQUEUE_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    //console.log(payload)
    try {
       const data = yield call(() => {
          return fetchAuditQueueDetail(payload.uniqueID)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: START_AUDITQUEUE_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: START_AUDITQUEUE_FAILURE });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    }
  });
}



export function* auditQueueSuccess() {
  yield takeEvery(START_AUDITQUEUE_SUCCESS, function*(payload) {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Audit Started",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

  });
}

export function* auditQueueError() {
  yield takeEvery(START_AUDITQUEUE_FAILURE, function*() {

    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Audit Could Not Start Working Session. Please try again.",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

  });
}


export function* auditDetailRequest() {
  yield takeEvery(GET_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    //console.log(payload)
    try {
       const data = yield call(() => {
          return fetchAuditDetail(payload.uniqueID)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: GET_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: GET_FAILURE });

    }
  });
}



export function* auditDetailSuccess() {
  yield takeEvery(GET_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* auditDetailError() {
  yield takeEvery(GET_FAILURE, function*() {});
}

export function* auditTypeRequest() {
  yield takeEvery(GET_TYPE_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    //console.log(payload)
    try {
       const data = yield call(() => {
          return fetchAuditTypes()
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: GET_TYPE_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: GET_TYPE_FAILURE });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    }
  });
}

export function* auditTypeSuccess() {
  yield takeEvery(GET_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* auditTypeError() {
  yield takeEvery(GET_FAILURE, function*() {
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Audit Could Retrieve Audit Categories. Please try again.",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}

export function* dashboardRequest() {
  yield takeEvery(GET_DASHBOARD_REQUEST, function*({ payload }) {
    // yield put({
    //   type: '@@app/UPDATE_LOADING',
    //   payload: true,
    // });
    //console.log(payload)
    try {
       const data = yield call(() => {
          return fetchDashboard()
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: GET_DASHBOARD_SUCCESS,
         payload: data,
       });
       // yield put({
       //   type: '@@app/UPDATE_LOADING',
       //   payload: false,
       // });

    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: GET_FAILURE });

    }
  });
}

export function* dashboardSuccess() {
  yield takeEvery(GET_DASHBOARD_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* dashboardError() {
  yield takeEvery(GET_DASHBOARD_FAILURE, function*() {});
}

export function* createAuditRequest() {
  yield takeEvery(CREATE_AUDIT_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return createAudit(payload.auditCreateList, payload.audit_name)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: CREATE_AUDIT_SUCCESS,
         payload: data,
       });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       yield put({
         type: '@@app/ALERTMESSAGE',
         payload: `${payload.audit_name} Created Successfully! Redirecting to work queue.`,
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: CREATE_AUDIT_FAILURE });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    }
  });
}

export function* createAuditSuccess() {
  yield takeEvery(CREATE_AUDIT_SUCCESS, function*(payload) {
    yield put({
      type: '@@app/REDIRECT',
      payload: "/queue",
    });
  });
}

export function* createAuditError() {
  yield takeEvery(CREATE_AUDIT_FAILURE, function*() {
      yield put({
        type: '@@app/ALERTMESSAGE',
        payload: "Audit failed to create. Please try again.",
      });

      delay(3000)

      yield put({
          type: '@@app/CLOSE_ALERT'
        });

  });
}


export function* updateAuditRequest() {
  yield takeEvery(UPDATE_AUDIT_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return updateAudit(payload)
        });
       yield put({
         type: UPDATE_AUDIT_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       yield put({
         type: '@@app/ALERTMESSAGE',
         payload: "Audit Section Saved Successfully...",
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: UPDATE_AUDIT_FAILURE });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    }
  });
}

export function* updateAuditSuccess() {
  yield takeEvery(UPDATE_AUDIT_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* updateAuditError() {
  yield takeEvery(UPDATE_AUDIT_FAILURE, function*(payload) {
    //console.log(payload)
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Missing audit fields, please try again...",
    });

    yield put({
        type: '@@app/CLOSE_ALERT'
      });

  });
}

export function* updateMainAuditRequest() {
  yield takeEvery(UPDATE_MAIN_AUDIT_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    try {
       const data = yield call(() => {
          return updateMainAudit(payload)
        });
       yield put({
         type: UPDATE_MAIN_AUDIT_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

       yield put({
         type: '@@app/ALERTMESSAGE',
         payload: "Audit Saved Successfully...",
       });

       delay(3000)

       yield put({
           type: '@@app/CLOSE_ALERT'
         });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: UPDATE_MAIN_AUDIT_FAILURE });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    }
  });
}

export function* updateMainAuditSuccess() {
  yield takeEvery(UPDATE_MAIN_AUDIT_SUCCESS, function*(payload) {
    //console.log(payload)
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* updateMainAuditError() {
  yield takeEvery(UPDATE_MAIN_AUDIT_FAILURE, function*(payload) {
    //console.log(payload)
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Missing audit fields, please try again...",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });
  });
}


export function* auditReportRequest() {
  yield takeEvery(AUDIT_REPORT_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    //console.log(payload)
    try {
       const data = yield call(() => {
          //console.log(payload)
          return createReport(payload.uniqueID)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: AUDIT_REPORT_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: AUDIT_REPORT_FAILURE });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });

    }
  });
}

export function* auditReportSuccess() {
  yield takeEvery(AUDIT_REPORT_SUCCESS, function*(payload) {
    //console.log(payload)
     yield put({
       type: '@@app/ALERTMESSAGE',
       payload: "Report generated successfully, please wait for your download...",
     });

     delay(3000)

     yield put({
         type: '@@app/CLOSE_ALERT'
       });

    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* auditReportError() {
  yield takeEvery(AUDIT_REPORT_FAILURE, function*(payload) {
    //console.log(payload)
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Report did not generate, please try again...",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });


  });
}

export function* supportRequest() {
  yield takeEvery(SUPPORT_REQUEST, function*({ payload }) {
    yield put({
      type: '@@app/UPDATE_LOADING',
      payload: true,
    });
    //console.log(payload)
    try {
       const data = yield call(() => {
          //console.log(payload)
          return createSupportTicket(payload)
        });
       yield put({type: "FETCH_SUCCEEDED", data})
       yield put({
         type: SUPPORT_SUCCESS,
         payload: data,
       });
       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    } catch (error) {

       yield put({type: "FETCH_FAILED", error})
       yield put({ type: SUPPORT_FAILURE });

       yield put({
         type: '@@app/UPDATE_LOADING',
         payload: false,
       });


    }
  });
}

export function* supportSuccess() {
  yield takeEvery(SUPPORT_SUCCESS, function*(payload) {
    //console.log(payload)
     // yield put({
     //   type: '@@app/ALERTMESSAGE',
     //   payload: "Support request sent successfully...",
     // });
    //yield notification('success', 'API is running & is up to date')
    //yield localStorage.setItem('test', payload.test);
  });
}

export function* supportError() {
  yield takeEvery(SUPPORT_FAILURE, function*(payload) {
    //console.log(payload)
    yield put({
      type: '@@app/ALERTMESSAGE',
      payload: "Support request did not send, please try again...",
    });

    delay(3000)

    yield put({
        type: '@@app/CLOSE_ALERT'
      });


  });
}


export default function* auditSaga() {
  yield all([
    //fork(checkAuthorization),
    fork(auditQueueRequest),
    fork(auditQueueSuccess),
    fork(auditQueueError),
    fork(auditRequest),
    fork(auditSuccess),
    fork(auditError),
    fork(auditTypeRequest),
    fork(auditTypeSuccess),
    fork(auditTypeError),
    fork(auditReportRequest),
    fork(auditReportSuccess),
    fork(auditReportError),
    fork(createAuditRequest),
    fork(createAuditSuccess),
    fork(createAuditError),
    fork(updateAuditRequest),
    fork(updateAuditSuccess),
    fork(updateAuditError),
    fork(updateMainAuditRequest),
    fork(updateMainAuditSuccess),
    fork(updateMainAuditError),
    fork(dashboardRequest),
    fork(dashboardSuccess),
    fork(dashboardError),
    fork(planSelectionRequest),
    fork(planSelectionSuccess),
    fork(planSelectionError),
    fork(supportRequest),
    fork(supportSuccess),
    fork(supportError),
  ]);
}
