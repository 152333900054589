import { createBrowserHistory, createMemoryHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import rootReducer from "../reducers/index";
import rootSaga from '../sagas/index'
//const configureStore = createStore(rootReducer);
import { configureStore } from "@reduxjs/toolkit";


import createSagaMiddleware from 'redux-saga'
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga)

// if (process.env.NODE_ENV === "development") {
//   module.hot.accept("../reducers/index", () => {
//     const newRootReducer = require("../reducers/index").default;
//     store.replaceReducer(newRootReducer);
//   });
// }


// const store = () => {
//   const isServer = typeof window === 'undefined';
//
//   const history = isServer
//     ? createMemoryHistory({ initialEntries: ['/'] })
//     : createBrowserHistory();
//
//   const middlewares = [routerMiddleware(history)];
//
//   const enhancers = composeWithDevTools(applyMiddleware(...middlewares));
//
//   const rootStore = createStore(
//     rootReducer(history),
//     {},
//     enhancers,
//   );
//
//   return {
//     rootStore,
//     history,
//   };
//
// };

export type AppDispatch = typeof store.dispatch;
export default store;
