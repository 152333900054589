/* @flow */
import { actionGenerator } from './index';
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const API_DIAGNOSTIC = "@@auth/API_DIAGNOSTIC";   /*** M.Logic: Dev Diagnostic Alert ***/
export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';
export const USER_VERIFICATION_REQUEST = '@@USER_VERIFICATION_REQUEST';
export const USER_VERIFICATION_SUCCESS = '@@USER_VERIFICATION_SUCCESS';
export const USER_VERIFICATION_FAILURE = '@@USER_VERIFICATION_FAILURE';
export const REGISTER_REQUEST = '@@auth/REGISTER_REQUEST';
export const REGISTER_SUCCESS = '@@auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = '@@auth/REGISTER_FAILURE';
export const LOGOUT_REQUEST = "@@auth/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "@@auth/LOGOUT_SUCCESS";
export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';

export const RESETPW_REQUEST = '@@auth/RESETPW_REQUEST';
export const RESETPW_SUCCESS = '@@auth/RESETPW_SUCCESS';
export const RESETPW_FAILURE = '@@auth/RESETPW_FAILURE';

export const RESETPW_CONFIRM_REQUEST = '@@auth/RESETPW_CONFIRM_REQUEST';
export const RESETPW_CONFIRM_SUCCESS = '@@auth/RESETPW_CONFIRM_SUCCESS';
export const RESETPW_CONFIRM_FAILURE = '@@auth/RESETPW_CONFIRM_FAILURE';

export const CHANGEPW_REQUEST = '@@auth/CHANGEPW_REQUEST';
export const CHANGEPW_SUCCESS = '@@auth/CHANGEPW_SUCCESS';
export const CHANGEPW_FAILURE = '@@auth/CHANGEPW_FAILURE';

export const UPDATE_USER_REQUEST = '@@auth/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = '@@auth/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = '@@auth/UPDATE_USER_FAILURE';

export const PORTAL_REQUEST = '@@auth/PORTAL_REQUEST';
export const PORTAL_SUCCESS = '@@auth/PORTAL_SUCCESS';
export const PORTAL_FAILURE = '@@auth/PORTAL_FAILURE';


export const UPDATE_TOKEN = '@@UPDATE_TOKEN';

export const GET_ME = actionGenerator('@@GET_ME');

interface UserObj {
  uniqueID: number,
  username: string,
  email: string,
  first_name: string,
  last_name: string
}

interface updateUserStruct {
  username: string,
  email: string,
  first_name: string,
  last_name: string
}
interface JWT {
  token: string;
  user: UserObj;
}

/*** M.Logic: Dev Diagnostic Alert ***/
// interface diagnosisStruct {
//   tObj: object;
//   tStr: string;
// } 

interface loginStruct {
  username: string;
  password: string;
}

interface userVerifyStruct {
  email: string;
  verify_key: string;
}

interface resetPWStruct {
  email: string;
}



interface changePWStruct {
  new_password1: string;
  new_password2: string;
}

interface resetPWConfirmStruct {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

interface registerStruct {
  password1: string,
  password2: string,
  username: string,
  email: string,
  first_name: string,
  last_name: string,
  company_name?: string,
  companyCode?: string,
  companyType?: string,
  employee_pin?: string
}

export const logoutRequest = createAction(LOGOUT_REQUEST);

/*** M.Logic: Dev Diagnostic Alert ***/
// export const apiDiagnostic = createAction(API_DIAGNOSTIC, function prepare(
//   diagnosis: diagnosisStruct,
// ) {
//   return {
//     payload: diagnosis,
//   };
// });

/*** M.Logic: Dev Diagnostic Alert ***/
export const apiDiagnostic = createAction(API_DIAGNOSTIC);

export const loginRequest = createAction("LOGIN_REQUEST", function prepare(
  cred: loginStruct,
) {
  return {
    payload: cred,
  };
});

export const userVerificationRequest = createAction(USER_VERIFICATION_REQUEST, function prepare(
  cred: userVerifyStruct,
) {
  return {
    payload: cred,
  };
});

export const registerRequest = createAction(REGISTER_REQUEST, function prepare(
  register: registerStruct,
) {
  return {
    payload: register,
  };
});

export const portalGetRequest = createAction(PORTAL_REQUEST);

export const resetPWRequest = createAction(RESETPW_REQUEST, function prepare(
  email: resetPWStruct,
) {
  return {
    payload: email,
  };
});

export const pwConfirmRequest = createAction(RESETPW_CONFIRM_REQUEST, function prepare(
  confirm: resetPWConfirmStruct,
) {
  return {
    payload: confirm,
  };
});

export const changePWRequest = createAction(CHANGEPW_REQUEST, function prepare(
  newPass: changePWStruct,
) {
  return {
    payload: newPass,
  };
});

export const updateUserRequest = createAction(UPDATE_USER_REQUEST, function prepare(
  user: updateUserStruct,
) {
  return {
    payload: user,
  };
});
