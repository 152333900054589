import {  IonGrid, IonRow, IonCol, IonBadge, IonSearchbar, IonSlides, IonSlide, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { libraryOutline, archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Audits.css';
import { connect, useDispatch } from 'react-redux';
import { useLocation, RouteComponentProps, useHistory} from 'react-router-dom';

import { auditGetRequest } from '../../actions/audits';

import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { AuditDetail } from './AuditDetail';
var slugify = require('slugify')

const Audits: React.FC = (props) => {

  const { name } = useParams<{ name: string; }>();
  const [searchText, setSearchText] = useState('');
  const [searchText2, setSearchText2] = useState('');

  const [audits, set_audits] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  let { slug } = useParams<{ slug: string }>();
  const [auditSlug, set_auditSlug] = useState('');

  const [showDetail, set_showDetail] = useState(false);
  const [activeAuditObject, set_activeAuditObject] = useState({});


  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  const selectGlobal2 = (state: RootState) => state.global
  const globalStateApp = useSelector(selectGlobal2);

  const completed = audits.filter((item:any) => item.status === "Done");
  const inProgress = audits.filter((item:any) => item.status === "In Progress");
  const notStarted = audits.filter((item:any) => item.status === "Not Started");

  const allNotDone = [...inProgress, ...notStarted ]

  const history = useHistory();

  useEffect(() => {

     if(globalStateApp.redirect_url !== null){
       setTimeout(() => history.push(globalStateApp.redirect_url), 3000);
     }

     if(globalState.auditList !== null){
       var data = JSON.parse(globalState.auditList)
       var auditsParsed = data.audits
       //console.log(slug)
       set_auditSlug(slug)

       if(slug !== '' || slug !== null){
         for(var i=0; i < auditsParsed.length; i++){
            if(slug === slugify(auditsParsed[i].name)){
              set_activeAuditObject(auditsParsed[i])
              set_auditSlug(slug)
              set_showDetail(true)
            }
         }
       }
       set_audits(auditsParsed)
     }else{
       setTimeout(() => dispatch(auditGetRequest()), 500);
     }
  },[globalState.auditList, globalStateApp.redirect_url])

  const slideOpts = {
    initialSlide: 1,
    speed: 400
  };

  function renderStatus(status:string) {
    if(status === "Done"){
      return(<IonBadge color="success">{status}</IonBadge>);
    }else if(status === "In Progress"){
      return(<IonBadge color="warning">{status}</IonBadge>)
    }else{
      return(<IonBadge color="light">{status}</IonBadge>)
    }
  }

  // function renderRegulation(reg:string) {
  //   if(reg === "Done"){
  //     return(<IonBadge color="success">{reg}</IonBadge>);
  //   }else if(reg === "In Progress"){
  //     return(<IonBadge color="warning">{reg}</IonBadge>)
  //   }else{
  //     return(<IonBadge color="light">{reg}</IonBadge>)
  //   }
  // }


  function handleItemDetailLink(item:any) {
    set_activeAuditObject(item)
    set_showDetail(true)
  }


  function renderTableItem(item: any) {
      return(
        <IonItem button onClick={() => {handleItemDetailLink(item)}} detail={true} >
          <IonLabel>{item.name}</IonLabel>
          {renderStatus(item.status)}
        </IonItem>
        );
  }

  const RenderTable: React.FC<{audits:any, searchText:string}> = ({children, audits, searchText}) => {
    //console.log(audits)
    var data = audits
    var renderList = [];

    //const [filterText, set_filterText] = React.useState('');


    let filteredItems = data.filter((item:any) => item.name && item.name.toLowerCase().includes(searchText.toLowerCase()));

    filteredItems = filteredItems.sort(function(a:any, b:any){
        return b.id - a.id;
    });

    //console.log(filteredItems)
    for(var i=0;i< filteredItems.length; i++){
      renderList.push(renderTableItem(filteredItems[i]))
    }
    return(
      <IonList>
        {renderList}
      </IonList>
    )

  }

  const renderList = (audits:Array<any>) => (

      audits.map((audit:any) => (
       <IonRow>
         <IonCol>
           <IonLabel>1</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>FDA</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>Manangement</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>0.1HRC Sec. 123</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>Blah blah blah..</IonLabel>
         </IonCol>
         <IonCol>
           <IonButton size="small"><IonIcon  icon={optionsOutline} /></IonButton>
           <IonButton size="small">View</IonButton>
         </IonCol>
       </IonRow>
    ))
  )

  // var fetch = require('superfetch');
  //
  // fetch.get.option({ encoding: 'utf8', headers: {
  //   'User-Agent': 'request',
  //   'Authorization':"Basic Y2NyOmFkbWlu",
  //   'Content-Type': "application/json",
  //   'Accept': 'application/json'
  //   },
  // })('http://127.0.0.1:8000/audit-types/').then(function (body: any) {
  //   console.log(body)
  //   // when the status code begins with "2", the response body will be returned.
  // }).catch(function (response : any) {
  //   // otherwise, the whole response(including headers) is returned.
  // });


  return (
    <>
    {showDetail
      ? (<AuditDetail activeAuditObject={activeAuditObject} showDetail={showDetail} set_showDetail={set_showDetail} slug={auditSlug}/>)
      : (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>Audits</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Audits</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonGrid>
              <IonRow>
                <IonCol size={'4'}>
                  <IonIcon class="audit-icon" ios={libraryOutline} md={libraryOutline} />
                </IonCol>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonLabel><b>Create, edit, and manage your audits here.</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Number of Audits Completed: <b>{completed.length}</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Number of Audits In Progress: <b>{inProgress.length}</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Number of Audits Not Started: <b>{notStarted.length}</b></IonLabel>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Completed Audits</IonCardTitle>
                <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
              </IonCardHeader>

              <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Audit Name</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>Status</IonLabel>
                  </IonCol>
                </IonRow>
                {completed.length > 0
                  ? (<RenderTable audits={completed} searchText={searchText}/>)
                  : (<IonRow>
                     <IonCol>
                       <IonLabel>No Audits Completed </IonLabel>
                     </IonCol>
                    </IonRow>)

                }



              </IonGrid>

              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Incomplete / Not Started Audits</IonCardTitle>
                <IonSearchbar value={searchText2} onIonChange={e => setSearchText2(e.detail.value!)}></IonSearchbar>
              </IonCardHeader>

              <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Audit Name</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>Status</IonLabel>
                  </IonCol>
                </IonRow>
                {allNotDone.length > 0
                  ? (<RenderTable audits={allNotDone} searchText={searchText2}/>)
                  : (<IonRow>
                     <IonCol>
                       <IonLabel>No Incomplete Audits found. </IonLabel>
                     </IonCol>
                    </IonRow>)

                }



              </IonGrid>

              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonPage>
      )
    }
    </>
  );
};

Audits.defaultProps = {
 slug: '', // This value is adopted when name prop is omitted.
}
// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(Audits);
