import { connectRouter } from 'connected-react-router';
//import { combineReducers } from 'redux'
import * as app from '../actions/app'
import * as auth from '../actions/auth'
import * as audits from '../actions/audits'

import { combineReducers } from "@reduxjs/toolkit";
import { LoginReducer, AuthReducer } from './auth';
import { AuditReducer } from './audits';


const IncreaseBusyCountActionType = "IncreaseBusyCount";


export interface Action<T, P> {
    readonly type: T;
    readonly payload?: P;
}

export function createAction<T extends string, P>(type: T, payload: P): Action<T, P> {
    return { type, payload };
}

interface IAppState {
  clientID: string,
  isLoggedIn: boolean,
  isVerified: boolean,
  isLoading: boolean,
  accessToken: string,
  refreshToken: string,
  user: any,
  theme: string,
  redirect_url: string,
  alertMsg: string,
  showMsg: boolean,
}


const initialState = {
  clientID: null,
  isLoggedIn: false,
  isVerified: false,
  isLoading: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  theme: 'light',
  alertMsg: null,
  showMsg: false,
  redirect_url:null,
};

const global = (state = initialState, action : Action<any, any>) => {
  switch (action.type) {
    case auth.UPDATE_TOKEN: {
      let s = {
        ...state,
        ...action.payload,
      };

      if (!s.accessToken) {
        s = { ...s, user: null };
      }

      return s;
    }

    case app.REDIRECT: {
      return { ...state, redirect_url: action.payload };
    }


    case app.UPDATE_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    case app.ALERT_MESSAGE: {
      return { ...state, alertMsg: action.payload, showMsg: true };
    }
    case app.UPDATE_THEME: {
      return { ...state, theme: action.payload };
    }
    case app.CLOSE_ALERT: {
      return { ...state, showMsg: false, alertMsg:null };
    }
    case auth.GET_ME.SUCCESS: {
      return { ...state, user: action.payload.user, accessToken: action.payload.accessToken, isVerified:action.payload.isVerified, isLoggedIn: action.payload.isLoggedIn };
    }
    case auth.GET_ME.ERROR: {
      return { ...state, user: null };
    }
    case auth.REGISTER_SUCCESS: {
      return { ...state, isLoggedIn: true };
    }
    case audits.PLAN_REQUEST_SUCCESS: {
      return { ...state, redirect_url: action.payload.link.url};
    }
    default:
      return { ...state };
  }
};


const rootReducer = combineReducers({
  global,
  login: LoginReducer,
  audits: AuditReducer,
  auth: AuthReducer,
});


export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;


// const rootReducer = (history) =>
//   combineReducers({
//     router: connectRouter(history),
//     global,
//   });
//
//
// // function rootReducer(state = initialState, action) {
// //   return state;
// // };
//
// export default rootReducer;
