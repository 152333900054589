import { AppConfigurationClient } from "@azure/app-configuration";
import { setApiRoot, getApiRoot } from '../sagas/api';

let azSettings:any = {};

function setAzSettings(settings:any){
  azSettings = settings;
}

export function getAzObj(){
  return azSettings;
}

export async function getAzConfigSettings(){

  const nuClient = new AppConfigurationClient("Endpoint=https://alpha-at-config.azconfig.io;Id=SZeM;Secret=53JLhWTM0UPhmmLp0Gs7FpHsohtyjFKGGOEBSjIyMhM=");
  const feHost = window.location.hostname;
  let settings:any = {};
  let azFilter:string;
  let slotPrefix:string;
  switch(feHost){
    case 'audittech-react-fe2-dev.azurewebsites.net':
      azFilter = "dev";
      slotPrefix = "DEV_";
    break;

    case 'audittech-react-fe2-qa.azurewebsites.net':
      azFilter = "qa";
      slotPrefix = "QA_";
    break;

    case 'audittech-react-fe2.azurewebsites.net':
      azFilter = "live";
      slotPrefix = "LIVE_";
    break;

    case 'work.audittech.net':
      azFilter = "live";
      slotPrefix = "LIVE_";
    break;

    default:
      azFilter = "dev";
      slotPrefix = "DEV_";
    break;
  }
  const regStr = `^${slotPrefix}`;
  const slotRegex = new RegExp( regStr, 'gi' );
  //console.log("ML Diagnostic ==> AZ-TOOLS - FE HOSTNAME: ", feHost);

  try {
    const azList = await nuClient.listConfigurationSettings({ labelFilter: azFilter });
    settings["STAGE"] = azFilter;
    //console.log("ML Diagnostic ==> AZ-TOOLS - STAGE: ", settings["STAGE"]);

    for await (const setting of azList) {
      const settingName = setting.key.replace(slotRegex, "");
      const value = setting.value;

      settings[settingName] = value;
    }
    await setApiRoot(settings['API_ROOT']);
    setAzSettings(settings);

    return settings;
  } catch (error) {
    console.error("ML Diagnostic ERROR ==> AZ-TOOLS - CONFIG LIST: ", error);
    return { errMsg: error }
  }


}