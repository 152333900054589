import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonImg,
  IonButton
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { logoutRequest } from '../actions/auth';


import { home, libraryOutline, logOutOutline, peopleCircleOutline, helpBuoy, layersOutline ,archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Dashboard',
    url: '/',
    iosIcon: home,
    mdIcon: home
  },
  {
    title: 'Start / Resume Audits',
    url: '/queue',
    iosIcon: layersOutline,
    mdIcon: layersOutline
  },
  {
    title: 'My Audits',
    url: '/audits',
    iosIcon: libraryOutline,
    mdIcon: libraryOutline
  },
  {
    title: 'Support',
    url: '/support',
    iosIcon: helpBuoy,
    mdIcon: helpBuoy
  },
  {
    title: 'My Account',
    url: '/account',
    iosIcon: peopleCircleOutline,
    mdIcon: peopleCircleOutline
  },
];

const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

const Menu: React.FC = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  function handleLogout(e:any){
    setTimeout(() => dispatch(logoutRequest()), 10);
    setTimeout(() => window.location.reload(), 1500);

  }


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonImg class="logo" src={'https://bh-audit-app.s3.us-east-2.amazonaws.com/Official+Logos+v2.2.png'} />
          <IonNote>Auditing made easy.</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <br />
          <IonButton onClick={handleLogout}>
              <IonIcon slot="start" ios={logOutOutline} md={logOutOutline} />
              <IonLabel>Logout</IonLabel>
          </IonButton>
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
