import { IonApp, IonToast, useIonToast, IonLoading, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import Home from './pages/Home/Home';
import Audits from './pages/Audits/Audits';
import AuditCreate from './pages/Audits/AuditCreate';
import AuditDetail from './pages/Audits/AuditDetail';
import AuditReportOverview from './pages/Audits/AuditReportOverview';
import AuditStoreDetail from './pages/Audits/AuditStoreDetail';
import AuditQueue from './pages/Audits/AuditQueue';
import Profile from './pages/Profile/Profile';
import ResetPW from './pages/ForgotPassword/ResetPW';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import Support from './pages/Support/Support';
import React, { useState, useEffect } from 'react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import { useSelector, connect } from 'react-redux'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import {RootState} from './reducers/index';

import {getTokenObject, getUserObject } from './sagas/auth';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Plugins } from '@capacitor/core';
import { useStorage, useStorageItem, availableFeatures } from '@capacitor-community/react-hooks/storage';

const { Storage } = Plugins;


// export const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//         localStorage.getItem('user')
//             ? <Component {...props} />
//             : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
//     )} />
// )

async function fetchToken() {
   let fetchedToken = await getTokenObject()
   return fetchedToken
 }

 async function fetchUser() {
    let fetchedUser = await getUserObject()
    return fetchedUser
  }

export interface PrivateRouteProps extends RouteProps  {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    user?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    let { component: Component, user:user, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(props) => {
                return user !== null ? <Component {...props} /> : <Redirect to="/login" />;
            }}
        />
    );
};

// const PrivateRoute = ({ component: Component, user: user, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       getTokenObject() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect to="/login" />
//       )
//     }
//   />
// );



const App: React.FC = (props) => {

  const [isLoggedIn, set_isLoggedIn] = useState(false);
  // TS infers type: (state: RootState) => boolean

  const selectGlobal = (state: RootState) => state.global
  const globalState = useSelector(selectGlobal);
  const [showLoading, set_showLoading] = useState(false);
  const [showSuccessMsg, set_showSuccessMsg] = useState(false);
  const [alertMsg, set_alertMsg] = useState('');

  const [user, set_user] = React.useState(null);
  const [token, set_token] = React.useState('');

  //const [token, set_token] = useStorage('token');



  const [present, dismiss] = useIonToast();

  useEffect(() => {
     if(globalState.isLoggedIn === false){

       set_isLoggedIn(true)
     }else{
       set_isLoggedIn(false)

     }

     if(globalState.isLoading === true){
       set_showLoading(true)
     }else{
       set_showLoading(false)
     }

     if(globalState.showMsg === true){
       set_showSuccessMsg(true)
     }else{
       set_showSuccessMsg(false)
     }

     if(globalState.alertMsg !== alertMsg){
       set_alertMsg(globalState.alertMsg)
     }

     async function fetchToken() {
        let fetchedToken = await getTokenObject()
        return fetchedToken
      }

      async function fetchUser() {
         let fetchedUser = await getUserObject()
         return fetchedUser
       }

     var userAuth = fetchToken()
     var userObject = fetchUser()

     if(userAuth !== null){
        userAuth.then(value => {
          if(value !== null){
            set_token(value)
          }
          //console.log(value)
        })

        userObject.then(value => {
          if(value !== null){
            set_user(value)
          }
          //console.log(value)
        })
      // var parsedUser = JSON.parse(userCookie)
      // set_user(parsedUser)
      // set_isLoggedIn(true)
      //set_token()
     }


  },[globalState.isLoggedIn, globalState.showMsg, globalState.alertMsg, globalState.isLoading])

  useEffect(() => {
     //setTimeout(() => dispatch(loginRequest({username:"ccr", password:"admin"})), 2000);

     if(user === null){
       // async function fetchToken() {
       //    let fetchedToken = await getTokenObject()
       //    return fetchedToken
       //  }
       //
       //  async function fetchUser() {
       //     let fetchedUser = await getUserObject()
       //     return fetchedUser
       //   }


       var userAuth = fetchToken()
       var userObject = fetchUser()

       if(userAuth !== null){
          userAuth.then(value => {
            if(value !== null){
              set_token(value)
            }
            //console.log(value)
          })

          userObject.then(value => {
            if(value !== null){
              set_user(value)
            }
            //console.log(value)
          })
        // var parsedUser = JSON.parse(userCookie)
        // set_user(parsedUser)
        // set_isLoggedIn(true)
        //set_token()
       }

     }



  })

  return (
    <IonApp>
      <IonReactRouter forceRefresh={true}>
        <IonSplitPane contentId="main">
          {user !== null
            ? (<Menu />)
            : null
          }
          <IonLoading
          cssClass=''
          isOpen={showLoading}
          onDidDismiss={() => set_showLoading(false)}
          message={'Please wait...'}
          />
          <IonToast
            isOpen={showSuccessMsg}
            message={alertMsg}
            duration={3000}
          />

          <IonRouterOutlet id="main">
            {user !== null
              ? (<>
                <Route path="/" component={Home}  />
                <Route path="/audits" exact={true} component={Audits}/>
                <Route path="/audits/:slug" exact={true} component={Audits}/>
                <Route path="/audits/:slug/overview/" component={AuditReportOverview} exact={true}/>
                <Route path="/create" exact={true} component={AuditCreate}/>
                <Route path="/queue" exact={true} component={AuditQueue}/>
                <Route path="/audits/:slug/:id/" component={AuditStoreDetail} exact={true}/>
                <Route path="/account" exact={true} component={Profile}/>
                <Route path="/support" exact={true} component={Support}/>
                </>)
              : <Login />
            }
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/resetpw" component={ResetPW}>
            </Route>
            <Route path="/auth/password/reset/confirm/:uid/:token" component={ForgotPassword}>
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

// export default App;

export default connect((props: any) => ({
     global: props.global
}))(App);
