import {  IonGrid, IonRow, IonCol, IonBadge, IonSearchbar, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { layersOutline, colorWandOutline, refreshOutline, archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Profile.css';
import { connect, useDispatch } from 'react-redux';

import { loginRequest, portalGetRequest, changePWRequest, updateUserRequest} from '../../actions/auth';

import { dashboardGetRequest } from '../../actions/audits';
import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { RouteComponentProps } from "react-router-dom";
import {XYPlot, XAxis, YAxis, RadialChart, LabelSeries} from 'react-vis';
import { CreateAnimation } from "@ionic/react";
import { PieChart, Pie, ResponsiveContainer} from 'recharts';



interface Props extends RouteComponentProps<{}> {
  history:any,
  location:any,
  match:any,
}

const Profile: React.FC = (props) => {

  const { name } = useParams<{ name: string; }>();
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  //User input fields
  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [email, set_email] = useState('');
  const [username, set_username] = useState('');

  const [password1, set_password1] = useState('');
  const [password2, set_password2] = useState('');

  const [planInfo, set_planInfo] = useState<any[]>([]);

  const [balance, set_balance] = useState('');
  const [billingPeriod, set_billingPeriod] = useState('');

  const [portal, set_portal] = useState({url:""});
  const [link, set_link] = useState('');


  const [dashboardData, set_dashboardData] = useState([]);

  const selectGlobal = (state: RootState) => state.auth
  const globalState = useSelector(selectGlobal);

  const selectAuditData = (state: RootState) => state.audits
  const auditGlobalState = useSelector(selectAuditData);

  const [platformUpdates, set_platformUpdates] = useState<any[]>([]);
  const [user, set_user] = useState<any>({});



  const data01 = [
  { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
  ];
  const data02 = [
    { name: 'A1', value: 100 },
    { name: 'A2', value: 300 },
    { name: 'B1', value: 100 },
    { name: 'B2', value: 80 },
    { name: 'B3', value: 40 },
    { name: 'B4', value: 30 },
    { name: 'B5', value: 50 },
    { name: 'C1', value: 100 },
    { name: 'C2', value: 200 },
    { name: 'D1', value: 150 },
    { name: 'D2', value: 50 },
  ];

  const plans = [
    { planID: 'price_1IXte8EIHXMrWlo1BxnQmDVs', planName: 'Single User Plan Monthly' },
    { planID: 'price_1IXte8EIHXMrWlo1msbrubZt', planName: 'Single User Plan Yearly' },
    { planID: 'price_1IXtfMEIHXMrWlo1XfGgEzm1', planName: 'Business Plan Monthly' },
    { planID: 'price_1IXtfMEIHXMrWlo1jRAddYpn', planName: 'Business Plan Yearly' },
    { planID: 'price_1JFNdREIHXMrWlo1xBBu3k9T', planName: 'Base Plan Yearly' },
    { planID: 'price_1JFNctEIHXMrWlo1nWihlzWF', planName: 'Base Plan Monthly' },
    { planID: 'price_1JFRUXEIHXMrWlo1kmTGq7gG', planName: 'Base Plan Yearly' },
    { planID: 'price_1JFRUdEIHXMrWlo1xYu6UpXW', planName: 'Base Plan Monthly' }
  ];


  // useEffect(() => {
  //    setTimeout(() => dispatch(loginRequest({username:"ccr", password:"admin"})), 2000);
  // },[])

  useEffect(() => {


    if(globalState.portalLink !== null){
      var resp = JSON.parse(globalState.portalLink)
      set_portal(resp.portalLink)
      var portal_url = resp.portalLink;
      var updates = resp.updates;
      var respUser = resp.user;
      set_user(respUser)
      set_first_name(respUser.first_name)
      set_last_name(respUser.last_name)
      set_email(respUser.email)
      set_username(respUser.username)

      set_link(resp.portalLink.url)
      var stripe = resp.subList;
      set_planInfo(stripe.data)
      set_platformUpdates(updates)

    }else{
      setTimeout(() => dispatch(portalGetRequest()), 0);
    }

    // if(auditGlobalState.dashboardData !== null){
    //   var resp = JSON.parse(auditGlobalState.dashboardData)
    //   var stripe = resp.stripeData;
    //   set_planInfo(stripe)
    //   console.log(planInfo.data[0])
    // }

  },[globalState.portalLink, auditGlobalState.dashboardData, dashboardData])

  // useEffect(() => {
  //
  //   if(auditGlobalState.dashboardData !== null){
  //     var resp = JSON.parse(auditGlobalState.dashboardData)
  //     var stripe = resp.stripeData;
  //     set_planInfo(stripe)
  //     console.log(planInfo.data[0])
  //   }
  //
  // })



  function renderStatus(status:string) {
    if(status === "Done"){
      return(<IonBadge color="success">{status}</IonBadge>);
    }else if(status === "In Progress"){
      return(<IonBadge color="warning">{status}</IonBadge>)
    }else{
      return(<IonBadge color="light">{status}</IonBadge>)
      }
    }

    function handleItemDetailLink(item:any) {

    }

    function handleAccountUpdate(item:any) {
        setTimeout(() => dispatch(updateUserRequest({email:email, first_name:first_name, last_name:last_name,username:username})), 0);
    }

    function handlePasswordChange(item:any) {
        setTimeout(() => dispatch(changePWRequest({new_password1:password1, new_password2:password2})), 0);
    }


    function handleBillingPortalRequest(item:any) {
      setTimeout(() => window.open(link), 2000);
    }


    function renderTableItem(item: any) {
        return(
            <IonItem button onClick={() => {handleItemDetailLink(item)}} detail={true} >
              <IonLabel>{item.audit_name} : {item.regulation}</IonLabel>
              {renderStatus(item.status)}
            </IonItem>
          );
    }

    function renderTable(audits: any) {
      var data = JSON.parse(audits)
      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderTableItem(data[i]))
      }
      return renderList
    }

    const myData = [{angle: 5}, {angle: 5}, {angle: 2}]

    function renderSubTableItem(item: any) {
        var plan = {};
        var date = new Date(item.current_period_end * 1000);
        var start = new Date(item.current_period_start * 1000);

        // Will display time in 10:30:23 format
        var formattedTime = date.toLocaleDateString()
        var formattedTimeStart = start.toLocaleDateString()


        if(item.plan.id === plans[0].planID){
          plan = plans[0].planName
        }
        else if(item.plan.id === plans[1].planID){
          plan = plans[1].planName
        }
        else if(item.plan.id === plans[2].planID){
          plan = plans[2].planName
        }
        else if(item.plan.id === plans[3].planID){
          plan = plans[3].planName
        }
        else if(item.plan.id === plans[4].planID){
          plan = plans[4].planName
        }
        else if(item.plan.id === plans[5].planID){
          plan = plans[5].planName
        }else if(item.plan.id === plans[6].planID){
            plan = plans[6].planName
          }
        else if(item.plan.id === plans[7].planID){
            plan = plans[7].planName
        }else{
          plan = "No active plan."
        }
        return(
          <IonItem button>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>{plan}</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel>{formattedTimeStart}</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel>{formattedTime}</IonLabel>
                </IonCol>
                <IonCol>
                  {renderStatus(item.status)}
                </IonCol>

              </IonRow>
           </IonGrid>
         </IonItem>

          );
    }

    function renderSubTable(data: any) {
      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderSubTableItem(data[i]))
      }
      return renderList
    }

    function renderUpdateItem(item: any) {
        return(
            <IonItem button>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>{item.name}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>{item.text}</IonLabel>
                  </IonCol>
                  <IonCol>
                    {renderStatus(item.status)}
                  </IonCol>
                </IonRow>
             </IonGrid>
           </IonItem>
          );
    }


    function renderPlatformUpdates(data: any) {
      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderUpdateItem(data[i]))
      }
      return renderList
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>My Account</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">My Account</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Account Details</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol>
                      <IonLabel>First Name</IonLabel>
                      <IonInput value={first_name} class="input-field-account" placeholder="Enter new first name..." onIonChange={e => {set_first_name(e.detail.value!)}}></IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <IonLabel>Last Name</IonLabel>
                      <IonInput value={last_name} class="input-field-account" placeholder="Enter new last name..." onIonChange={e => {set_last_name(e.detail.value!)}}></IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <IonLabel>Username</IonLabel>
                      <IonInput value={username} class="input-field-account" placeholder="Enter new username..." onIonChange={e => {set_username(e.detail.value!)}}></IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <IonLabel>Email</IonLabel>
                      <IonInput value={email} class="input-field-account" placeholder="Enter new email..." onIonChange={e => {set_email(e.detail.value!)}}></IonInput>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <br/>
                <IonButton expand="block" onClick={handleAccountUpdate} ><IonIcon slot="start" ios={layersOutline} md={layersOutline}/>Save Account Details</IonButton>
                <br/>
                <IonRow>
                  <IonCol>
                  <IonLabel>New Password</IonLabel>
                  <IonInput value={password1} type="password" class="input-field-account" placeholder="Enter new password..." onIonChange={e => {set_password1(e.detail.value!)}}></IonInput>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                  <IonLabel>Confirm New Password</IonLabel>
                  <IonInput value={password2} type="password" class="input-field-account" placeholder="Confirm new password..." onIonChange={e => {set_password2(e.detail.value!)}}></IonInput>
                  </IonCol>
                </IonRow>
                <br/>
                <IonButton expand="block" onClick={handlePasswordChange} ><IonIcon slot="start" ios={layersOutline} md={layersOutline}/>Change Password</IonButton>
              </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonRow>
              <IonCol>
                <IonCardTitle>Billing Overview</IonCardTitle>
              </IonCol>
              <IonCol>
              <IonButton expand="block" color="dark" onClick={handleBillingPortalRequest}>Manage Billing<IonIcon slot="start" ios={paperPlaneSharp} md={paperPlaneSharp}/></IonButton>
              </IonCol>
            </IonRow>

          </IonCardHeader>
          <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Plan Name</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>Start of Billing Cycle</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>Next Due Date</IonLabel>
                  </IonCol>
                  <IonCol>
                  <IonLabel>Status</IonLabel>
                  </IonCol>

                </IonRow>
             </IonGrid>
              <br/>
              {planInfo.length > 0
                ? (<>{renderSubTable(planInfo)}</>)
                : (<>You are currently not subscribed to a plan. Please add your billing details to continue enjoying the app.</>)
              }
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Platform Updates</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
            <IonRow>
              <IonCol>
                {platformUpdates.length > 0
                ? (<>{renderPlatformUpdates(platformUpdates)}</>)
                : (<IonLabel color="dark">No new platform updates at the moment. Check back later for additional updates.</IonLabel>)
                }
              </IonCol>
            </IonRow>
            <IonButton expand="block" color="dark"  onClick={()=> {dispatch(portalGetRequest())}}>Refresh<IonIcon slot="start" ios={refreshOutline} md={refreshOutline}/></IonButton>

            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(Profile);
