import {  IonGrid, IonRow, IonCol, IonBadge, IonSearchbar, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonListHeader, IonItemDivider, IonCardSubtitle, IonCardTitle, IonSelect, IonSelectOption,IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Audits.css';
import { connect, useDispatch } from 'react-redux';
import { auditGetTypeRequest, createAuditRequest } from '../../actions/audits';
import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { useHistory } from "react-router-dom";
import { useIonAlert } from '@ionic/react';

const AuditCreate: React.FC = (props) => {

  const { name } = useParams<{ name: string; }>();
  const [audit_name, set_audit_name] = useState('');
  const [audit_type, set_audit_type] = useState('');
  const [auditPlanActive, set_auditPlanActive] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const [present] = useIonAlert();


  const [auditTypeList, set_auditTypeList] = useState([]);
  const [auditCreateList, setAuditCreateList] = useState([]);

  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  const selectGlobal2 = (state: RootState) => state.global
  const globalStateApp = useSelector(selectGlobal2);

  useEffect(() => {
     setTimeout(() => dispatch(auditGetTypeRequest()), 10);
  },[])

  useEffect(() => {
     if(globalState.auditTypes !== null){
       set_auditTypeList(globalState.auditTypes.auditTypes)
       set_auditPlanActive(globalState.auditTypes.planActive)

       if(globalState.auditTypes.planActive === false){
         present({
           header: 'Your plan is inactive.',
           message: 'Your subscription is either inactive, past-due, or disabled. Please click the manage billing button to update your subscription & continue creating additional audits.',
           buttons: [
             { text: 'Ok', handler: (d) => console.log('ok pressed') },
             { text: 'Manage My Billing', handler: (d) => setTimeout(() => history.push('/account'), 1000)},

           ],
           onDidDismiss: (e) => console.log('did dismiss'),
         })
       }

       //console.log(globalState.auditTypes.planActive)
     }

     // var resp = globalState.dashboardData
     // var recent = resp.recentAudits;
     // var stripe = resp.stripeData;


  },[globalState.auditTypes])

  useEffect(() => {
     if(globalStateApp.redirect_url !== null){
       setTimeout(() => history.push(globalStateApp.redirect_url), 2000);
     }
  },[globalStateApp.redirect_url])

    function handleAuditCreate(e:any) {
         dispatch(createAuditRequest({auditCreateList:auditCreateList, audit_name:audit_name}))
         //setTimeout(() => history.replace("/audits"), 3000);

    }

    function handleAuditSelect(e:any) {
         //dispatch(createAuditRequest({regulation:audit_type, audit_name:audit_name}))
         //setTimeout(() => history.replace("/audits"), 3000);
         setAuditCreateList(e.detail.value)
         //console.log(e.detail.value)

    }


    function getUniqueListBy(arr:any, key:any) {
      return [...new Map(arr.map((item:any) => [item[key], item])).values()]
    }

    function renderTableItem(item: any) {
        return(
              <IonSelectOption value={item.regulation}>{item.regulation}</IonSelectOption>
          );
    }

    function renderTable(audits: any) {
      var data = audits
      var uniqueAuditTypes = getUniqueListBy(data, 'regulation')
      var renderList = [];
      for(var i=0;i< uniqueAuditTypes.length; i++){
        renderList.push(renderTableItem(uniqueAuditTypes[i]))
      }
      return renderList
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Audit Creation</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Audit Creation</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Create A New Audit</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                  <IonList>
                      <IonListHeader>
                        <IonLabel>
                          1. Select the audit regulation type from below.
                        </IonLabel>
                      </IonListHeader>

                      <IonItem>
                        <IonLabel>Select A Regulation</IonLabel>
                        <IonSelect value={auditCreateList} onIonChange={e => {handleAuditSelect(e)}} placeholder="Select One" multiple={true}>
                          {auditTypeList && auditTypeList.length >= 1
                            ? (renderTable(auditTypeList))
                            : (<IonRow>
                               <IonCol>
                                 <IonLabel>1</IonLabel>
                               </IonCol>
                              </IonRow>)
                          }
                        </IonSelect>
                      </IonItem>
                      <br />
                      <IonListHeader>
                        <IonLabel>
                          2. Enter a name for this audit.
                        </IonLabel>
                      </IonListHeader>
                      <IonInput value={audit_name} class="input-field" placeholder="Enter audit name" onIonChange={e => {set_audit_name(e.detail.value!)}}></IonInput>
                      <br />
                      <IonButton onClick={handleAuditCreate} disabled={!auditPlanActive}>Create Audit</IonButton>
                      <IonButton color="danger" routerLink={'/audits'}>Cancel</IonButton>
                  </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(AuditCreate);
