import {  IonGrid, IonRow, useIonToast, IonLoading, IonImg, IonCol, IonLabel, IonButton,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox, IonSelect, IonSelectOption} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../../components/ExploreContainer';
import './SignUp.css';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { registerRequest } from '../../actions/auth';
import {RootState} from '../../reducers/index';
import { RouteComponentProps, useHistory } from "react-router-dom";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getAzConfigSettings } from '../../util/az-tools';

const SignUp: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  const [username, set_username] = useState('');
  const [password, set_password] = useState('');
  const [company_name, set_company_name] = useState('');
  const [employee_pin, set_employee_pin] = useState('');
  const [companyType, set_companyType] = useState('');
  const [companyCode, set_companyCode] = useState('');
  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [email, set_email] = useState('');
  const [password1, set_password1] = useState('');
  const [password2, set_password2] = useState('');

  const [showLoading, set_showLoading] = useState(false);
  const dispatch = useDispatch();

  const selectGlobal = (state: RootState) => state.global
  const globalState = useSelector(selectGlobal);

  const [present, dismiss] = useIonToast();

  const history = useHistory();

  const [regType, setRegType] = useState('company')
  const [vMode, set_vMode] = useState('live');
  //const [isModed, set_isModed] = useState(false);

  const handleRegTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null,
  ) => {
    setRegType(newType);
  };

  // useEffect(() => {
  //    //setTimeout(() => dispatch(loginRequest({username:"ccr", password:"admin"})), 2000);
  // },[])

  useEffect(() => {

      if(globalState.showMsg === true && globalState.alertMsg !== null ){
          present(globalState.alertMsg, 3000)
      }

      if(globalState.isLoggedIn === true ){
          history.push('/login')
      }


      const fetchAZ = async () => {
        // get the data from the api
        const aC = await getAzConfigSettings();

        if(aC["STAGE"] == 'live'){
          set_vMode('under_construct');
        }
        //set_isModed(true);
        console.log("AZ CONFIG STAGE ==>", aC["STAGE"]);
        return aC;
      };

      //if(!isModed){
      let azConfig:any = fetchAZ();
      //}

  },[globalState.showMsg, globalState.alertMsg, globalState.isLoggedIn])

  function handleUserCreation(e:any){
    setTimeout(() => dispatch(registerRequest({username:username, password1:password1, password2:password2, email:email, first_name:first_name, last_name:last_name, companyCode:companyCode, company_name:company_name, companyType:companyType, employee_pin:employee_pin})), 0)
  }

  return (
    <IonPage className="loginPage">
      { vMode=='under_construct' && 
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>B & H Engineering Concepts</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonImg className="logo" src={'https://atms01.blob.core.windows.net/$web/img/uc_text.png'} />
              <br />
              <IonTitle className="logo-subtitle" size="large">SERVICE COMING SOON</IonTitle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      }

      { vMode=='live' && 
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>B & H Engineering Concepts</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid>
          <IonRow>
            <IonCol>
                <IonImg className="logo" src={'https://atms01.blob.core.windows.net/$web/img/features.svg'} />
                <br/>
            </IonCol>
            <IonCol>
                <IonTitle className="logo-subtitle" size="small">Welcome to the Digital Auditor Application, we make auditing easy for professionals in a Regulated Industry.  {'\n'} The Digital Auditor App allows you to streamline your auditing process while simultaneously experiencing the future of auditing. Please complete the form below.</IonTitle>
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <ToggleButtonGroup
              color="primary"
              value={regType}
              exclusive
              onChange={handleRegTypeChange}
              aria-label="Registration Type"
            >
              <ToggleButton value="company">Company</ToggleButton>
              <ToggleButton value="employee">Employee</ToggleButton>
            </ToggleButtonGroup>
          </IonRow>
          <IonCard>
          {regType=='company' && 
            <IonCard>
              <IonRow>
                <IonCol>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Company Name</IonLabel>
                    <IonInput value={company_name} className="input-field-account" placeholder="Enter company name ..." onIonChange={e => {set_company_name(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonSelect value={companyType} onIonChange={e => {set_companyType(e.detail.value!)}} placeholder="Select company type ...">
                        <IonSelectOption value={"regulatory_agency"}>Regulatory Agency</IonSelectOption>
                        <IonSelectOption value={"medical_device"}>Medical Device</IonSelectOption>
                        <IonSelectOption value={"pharmacuetical"}>3 - Minor Finding</IonSelectOption>
                        <IonSelectOption value={"consulting_company"}>Consulting Company</IonSelectOption>
                        <IonSelectOption value={"other"}>Other</IonSelectOption>
                      </IonSelect>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Username</IonLabel>
                    <IonInput value={username} className="input-field-account" placeholder="Enter username..." onIonChange={e => {set_username(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>First Name</IonLabel>
                    <IonInput value={first_name} className="input-field-account" placeholder="Enter first name..." onIonChange={e => {set_first_name(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Last Name</IonLabel>
                    <IonInput value={last_name} className="input-field-account" placeholder="Enter last name..." onIonChange={e => {set_last_name(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Email</IonLabel>
                    <IonInput value={email} className="input-field-account" placeholder="Enter email..." onIonChange={e => {set_email(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                <IonLabel>Password</IonLabel>
                <IonInput value={password1} type="password" className="input-field-account" placeholder="Enter password..." onIonChange={e => {set_password1(e.detail.value!)}}></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                <IonLabel>Confirm Password</IonLabel>
                <IonInput value={password2} type="password" className="input-field-account" placeholder="Confirm password..." onIonChange={e => {set_password2(e.detail.value!)}}></IonInput>
                </IonCol>
              </IonRow>
              <br/>
              <IonRow>
                <IonCol size="6">
                <IonLabel>Company Code (Optional)</IonLabel>
                <IonLabel></IonLabel>
                <IonInput value={companyCode} type="password" className="input-field-account" placeholder="Enter company code..." onIonChange={e => {set_companyCode(e.detail.value!)}}></IonInput>
                </IonCol>
                <IonCol>
                <IonLabel className="centered">If you are signing up under a business account, please enter your company signup code, otherwise continue sign up.</IonLabel>
                </IonCol>
              </IonRow>
            </IonCard> }

          {regType=='employee' && 
            <IonCard>
              <IonRow>
                <IonCol>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Username</IonLabel>
                    <IonInput value={username} className="input-field-account" placeholder="Enter username..." onIonChange={e => {set_username(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>First Name</IonLabel>
                    <IonInput value={first_name} className="input-field-account" placeholder="Enter first name..." onIonChange={e => {set_first_name(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Last Name</IonLabel>
                    <IonInput value={last_name} className="input-field-account" placeholder="Enter last name..." onIonChange={e => {set_last_name(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <IonLabel>Email</IonLabel>
                    <IonInput value={email} className="input-field-account" placeholder="Enter email..." onIonChange={e => {set_email(e.detail.value!)}}></IonInput>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                <IonLabel>Password</IonLabel>
                <IonInput value={password1} type="password" className="input-field-account" placeholder="Enter password..." onIonChange={e => {set_password1(e.detail.value!)}}></IonInput>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                <IonLabel>Confirm Password</IonLabel>
                <IonInput value={password2} type="password" className="input-field-account" placeholder="Confirm password..." onIonChange={e => {set_password2(e.detail.value!)}}></IonInput>
                </IonCol>
              </IonRow>
              <br/>
              <IonRow>
                <IonCol size="6">
                <IonLabel>Employee PIN #</IonLabel>
                <IonLabel></IonLabel>
                <IonInput value={employee_pin}  className="input-field-account" placeholder="Ex: 765812" onIonChange={e => {set_employee_pin(e.detail.value!)}}></IonInput>
                </IonCol>
              </IonRow>
            </IonCard>}

          </IonCard>

          <IonRow>
            <IonCol>
            <IonButton expand="block" onClick={(e) => handleUserCreation(e)}>Sign Up</IonButton>
            </IonCol>
            <IonCol>
            <IonButton expand="block" className="fancy-button" routerLink={'/login'}>Login</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      }

    </IonPage>
  );
};

export default SignUp;
