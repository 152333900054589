import { createReducer } from "@reduxjs/toolkit";
import { auditGetRequest } from "../actions/audits";
import * as audits from '../actions/audits'


// export const AuditReducer = createReducer(
//   { auditObject },
//   {
//     [auditGetRequest.type]: (state, action) => ({
//       ...state,
//       auditList: { ...action.payload },
//     }),
//   }
// );

export interface Action<T, P> {
    readonly type: T;
    readonly payload?: P;
}

export function createAction<T extends string, P>(type: T, payload: P): Action<T, P> {
    return { type, payload };
}


const initialState = {
  auditList: null,
  auditTypes: null,
  activeQueue: [],
  dashboardData: null,
  report_url: '',
  successfulSubscribe: false,
};

export function AuditReducer(state = initialState, action : Action<any, any>){
  switch (action.type) {

    case audits.GET_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case audits.GET_SUCCESS: {
      return { ...state, auditList: action.payload };
    }
    case audits.GET_FAILURE: {
      return { ...state };
    }
    case audits.GET_DASHBOARD_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case audits.GET_DASHBOARD_SUCCESS: {
      return { ...state, dashboardData: JSON.parse(action.payload) };
    }
    case audits.GET_DASHBOARD_FAILURE: {
      return { ...state };
    }
    case audits.PLAN_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case audits.GET_TYPE_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case audits.GET_TYPE_SUCCESS: {
      return { ...state, auditTypes: JSON.parse(action.payload) };
    }
    case audits.GET_TYPE_FAILURE: {
      return { ...state };
    }
    case audits.AUDIT_REPORT_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case audits.AUDIT_REPORT_SUCCESS: {
      return { ...state, report_url: action.payload };
    }
    case audits.AUDIT_REPORT_FAILURE: {
      return { ...state };
    }
    case audits.START_AUDITQUEUE_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case audits.START_AUDITQUEUE_SUCCESS: {
      return { ...state, activeQueue: action.payload.auditStores };
    }
    case audits.START_AUDITQUEUE_FAILURE: {
      return { ...state };
    }
    case audits.UPDATE_AUDIT_SUCCESS: {
      return { ...state, activeQueue: state.activeQueue.map((auditStore:any) => (auditStore.id === action.payload.id) ? action.payload : auditStore), };
    }

    default:
      return { ...state };
  }
};
