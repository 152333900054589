import {  IonGrid, IonRow, IonLoading, IonImg, IonCol, IonLabel, IonButton,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox} from '@ionic/react';
import ExploreContainer from '../../components/ExploreContainer';
import './ForgotPassword.css';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { loginRequest, pwConfirmRequest,  } from '../../actions/auth';
import { useHistory, useParams, RouteComponentProps, withRouter} from 'react-router-dom';

const ForgotPassword: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  const [password1, set_password1] = useState('');
  const [password2, set_password2] = useState('');

  // const [token, set_token] = useState('');
  // const [uid, set_uid] = useState('')
  const params : any = useParams();


  const { uid } : any = useParams();
  const { token } : any = useParams();


  const [showLoading, set_showLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log(token)
     //setTimeout(() => dispatch(loginRequest({password1:"ccr", password:"admin"})), 2000);
  },[])

  return (
    <IonPage class="loginPage">
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>B & H Engineering Concepts</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid>
          <IonRow>
            <IonCol>
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonTitle class="logo-subtitle" size="small">To reset your password, please enter your new desired password below.</IonTitle>
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonInput value={password1} type="password" class="input-field" placeholder="Enter new password" onIonChange={e => {set_password1(e.detail.value!)}}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonInput value={password2} type="password" class="input-field" placeholder="Confirm new password" onIonChange={e => {set_password2(e.detail.value!)}}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonButton expand="block" onClick={ () => setTimeout(() => dispatch(pwConfirmRequest({new_password1:password1, new_password2:password2,uid:uid,token:token})), 0)}>Reset Password</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonButton expand="block" routerLink={'/'}>Back To Login</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
