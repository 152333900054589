import {  IonGrid, IonRow, useIonToast, IonLoading, IonImg, IonCol, IonLabel, IonButton,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../../components/ExploreContainer';
import './Login.css';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { loginRequest, apiDiagnostic } from '../../actions/auth';
import {RootState} from '../../reducers/index';
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getAzConfigSettings } from '../../util/az-tools';


const Login: React.FC = () => {
  //const params = new URLSearchParams(window.location.pathname);
  const { name } = useParams<{ name: string; }>();

  const [username, set_username] = useState('');
  const [vMode, set_vMode] = useState('live');
  //const [isModed, set_isModed] = useState(false);
  const [password, set_password] = useState('');
  const [showLoading, set_showLoading] = useState(false);
  const [diagShown, set_diagShown] = useState(false);
  const dispatch = useDispatch();

  const selectGlobal = (state: RootState) => state.global
  const globalState = useSelector(selectGlobal);

  const [present, dismiss] = useIonToast();

  const history = useHistory();

  useEffect(() => {

      if(globalState.showMsg === true && globalState.alertMsg !== null ){
          present(globalState.alertMsg, 3000)
      }

      if(globalState.user !== null ){
          history.push('/')
      }


      const fetchAZ = async () => {
        // get the data from the api
        const aC = await getAzConfigSettings();

        if(aC["STAGE"] == 'live'){

          set_vMode('under_construct');
        }
        //set_isModed(true);
        console.log("AZ CONFIG STAGE ==>", aC["STAGE"]);
        return aC;
      };

      //if(!isModed){
      const azConfig:any = fetchAZ();
      
      //}

      // if(!diagShown){
      //   mlDiag();
      //   set_diagShown(true);
      // }

  },[globalState.showMsg, globalState.alertMsg, globalState.user])


  function handleLogin(){
    setTimeout(() => dispatch(loginRequest({username:username, password:password})), 0)
    //setTimeout(() => history.push('/'), 4000)  /** Not used in original source */
  }

  function mlDiag(){
    setTimeout(() => dispatch(apiDiagnostic()), 10)
  }

  return (
    <IonPage className="loginPage">
      {vMode=='under_construct' && 
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>B & H Engineering Concepts</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonImg className="logo" src={'https://atms01.blob.core.windows.net/$web/img/uc_text.png'} />
              <br />
              <IonTitle className="logo-subtitle" size="large">SERVICE COMING SOON</IonTitle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      }

      {vMode=='live' && 
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>B & H Engineering Concepts</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid>
          <IonRow>
            <IonCol>
                <IonImg className="logo" src={'https://atms01.blob.core.windows.net/$web/img/hero-img.png'} />
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonTitle className="logo-subtitle" size="small">Welcome to the Digital Auditor Application.  Please Log in or Sign Up to continue</IonTitle>
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonInput value={username} type="text" className="input-field" placeholder="Enter username" onIonChange={e => {set_username(e.detail.value!)}}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonInput value={password} type="password" className="input-field" placeholder="Enter password" onIonChange={e => {set_password(e.detail.value!)}}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonButton expand="block" onClick={ () => handleLogin()}>Login</IonButton>
            </IonCol>
            <IonCol>
            <IonButton expand="block" className="fancy-button" routerLink={'/signup'}>Sign Up</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonButton expand="block" className="fancy-button" routerLink={'/resetpw'}>Forgot Password?</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      }
    </IonPage>
  );
};

export default Login;
