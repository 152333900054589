import {  IonGrid, IonProgressBar, IonRow, IonCol, IonBadge, IonSearchbar, IonSlides, IonSlide, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Audits.css';
import { connect, useDispatch } from 'react-redux';
import { useLocation, RouteComponentProps } from 'react-router-dom';

import { auditGetRequest, auditReportRequest} from '../../actions/audits';
import { AuditReportOverview } from './AuditReportOverview';

import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
var slugify = require('slugify')


interface IProps {
  showDetail: boolean,
  set_showDetail: any,
  activeAuditObject: any,
  slug: string,
}

export const AuditDetail: React.FC<IProps> = ({activeAuditObject, set_showDetail, showDetail}) => {

  const { name } = useParams<{ name: string; }>();
  const [searchText, setSearchText] = useState('');
  const [audits, set_audits] = useState<any[]>([]);
  let { slug } = useParams<{ slug: string }>();
  const [reportURL, set_reportURL] = useState('');

  const [activeRedirect, set_activeRedirect] = useState(false);
  const [overview, set_overview] = useState(false);
  const [progressPercent, set_progressPercent] = useState<any>(0);


  const dispatch = useDispatch();
  const location = useLocation();

  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  useEffect(() => {
     //setTimeout(() => dispatch(auditGetRequest()), 10);
     if(globalState.report_url.report_url !== ''){
       set_reportURL(globalState.report_url.report_url)
     }

     if(globalState.auditList !== null){
       var data = JSON.parse(globalState.auditList)
       var auditsParsed = data.auditStores
       if(activeAuditObject.size !== 0){
         let filteredList: Array<any> = [];
         var numCompleted = 0;
         var total = 0

         for(var i=0; i < auditsParsed.length; i++){
            if(activeAuditObject.name === auditsParsed[i].audit_name){
              filteredList.push(auditsParsed[i])
              total = total+1
              if(auditsParsed[i].status === "Done"){
                numCompleted = numCompleted+1
              }

            }


         }
         set_audits(filteredList)
         var progressPercent = ((numCompleted / total) * 100)
         set_progressPercent(progressPercent)
       }

     }
  },[globalState.auditList, activeAuditObject, globalState.report_url])

  useEffect(() => {
     if(globalState.report_url.report_url !== ''){
       set_reportURL(globalState.report_url.report_url)
       if(activeRedirect === true){
          window.open(reportURL, '_self', 'location=yes')
          set_activeRedirect(false)
       }
     }
  })

  const slideOpts = {
    initialSlide: 1,
    speed: 400
  };

  function renderStatus(status:string) {
    if(status === "Done"){
      return(<IonBadge color="success">{status}</IonBadge>);
    }else if(status === "In Progress"){
      return(<IonBadge color="warning">{status}</IonBadge>)
    }else{
      return(<IonBadge color="light">{status}</IonBadge>)
    }
  }

  function handleReportCreate() {

    setTimeout(() => dispatch(auditReportRequest({uniqueID:activeAuditObject.uniqueID, audit_name: activeAuditObject.audit_name })), 10);

    setTimeout(() => set_activeRedirect(true), 1000);


  }

  function handleBackButton(item:any) {
    set_showDetail(false)
  }

  function truncateString(str: string, num:number) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  function renderPercent(num:number) {
    return num.toFixed(2)
  }


  function renderTableItem(item: any, i:number) {
      return(
        <IonItem button routerLink={"/audits/"+slugify(item.audit_name)+"/"+item.uniqueID+"/"} detail={true} >
          <IonLabel><b>{i+1}: {item.audit_name}</b> | {item.section} | {truncateString(item.details, 60)} | </IonLabel>
          {renderStatus(item.status)}
        </IonItem>
        );
  }

  function renderTable(audits: any) {
    var data = audits
    var renderList = [];

    const sortedAudits = data.sort(function(a:any, b:any){
        return a.id - b.id;
    });

    for(var i=0;i< sortedAudits.length; i++){
      renderList.push(renderTableItem(sortedAudits[i], i))
    }
    return(
      <IonList>
        {renderList}
      </IonList>
    )

  }

  const renderList = (audits:Array<any>) => (

      audits.map((audit:any) => (
       <IonRow>
         <IonCol>
           <IonLabel>1</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>FDA</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>Manangement</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>0.1HRC Sec. 123</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>Blah blah blah..</IonLabel>
         </IonCol>
         <IonCol>
           <IonButton size="small"><IonIcon  icon={optionsOutline} /></IonButton>
           <IonButton size="small">View</IonButton>
         </IonCol>
       </IonRow>
    ))
  )

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{activeAuditObject.name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{activeAuditObject.name || ''}</IonTitle>

          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size={'3'}>
              {slug === undefined
                ?(<IonButton color="" size="large" onClick={() => set_showDetail(false)} >Back</IonButton>)
                :(<IonButton color="" size="large" onClick={() => set_showDetail(false)} routerLink={'/audits'}>Back</IonButton>)
              }
            </IonCol>
            <IonCol>
              <IonRow>
                <IonCol>
                  <IonLabel>Audit ID: {activeAuditObject.uniqueID || ''}</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Status: {renderStatus(activeAuditObject.status) || ''}</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Percentage Complete: <b>{renderPercent(progressPercent)} %</b></IonLabel>
                  <IonProgressBar value={progressPercent/100} ></IonProgressBar>
                </IonCol>
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonCol>
                  <IonLabel><IonButton color="primary" size="large" onClick={() => set_overview(true)}>Report Overview</IonButton></IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>

        {overview
          ? (<>
              <AuditReportOverview activeAuditObject={activeAuditObject} overview={overview} set_overview={set_overview}/>
            </>)
          : (<>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Audit Sections</IonCardTitle>
                  <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
                </IonCardHeader>

                <IonCardContent>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Audit Name</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>Status</IonLabel>
                    </IonCol>
                  </IonRow>
                  {audits.length >= 1
                    ? (renderTable(audits))
                    : (<IonRow>
                       <IonCol>
                         <IonLabel>No Audit Available</IonLabel>
                       </IonCol>
                      </IonRow>)

                  }
                </IonGrid>
                </IonCardContent>
              </IonCard>

            </>)
        }
      </IonContent>
    </IonPage>
  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(AuditDetail);
