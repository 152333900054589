import {  IonGrid, IonRow, IonLoading, IonImg, IonCol, IonLabel, IonButton,IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox} from '@ionic/react';
import ExploreContainer from '../../components/ExploreContainer';
import './ForgotPassword.css';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { resetPWRequest } from '../../actions/auth';
import { useHistory, useParams, RouteComponentProps, withRouter} from 'react-router-dom';

const ResetPW: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  const [email, set_email] = useState('');

  // const [token, set_token] = useState('');
  // const [uid, set_uid] = useState('');


  const [showLoading, set_showLoading] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //    //setTimeout(() => dispatch(loginRequest({password1:"ccr", password:"admin"})), 2000);
  // },[])

  return (
    <IonPage class="loginPage">
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>B & H Engineering Concepts</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <IonGrid>
          <IonRow>
            <IonCol>
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonTitle class="logo-subtitle" size="small">To reset your password, please enter your email below. We will send a link to reset yor password.</IonTitle>
                <br/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
                <IonInput value={email} type="text" class="input-field" placeholder="Enter email..." onIonChange={e => {set_email(e.detail.value!)}}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonButton expand="block" onClick={ () => setTimeout(() => dispatch(resetPWRequest({email:email})), 0)}>Reset Password</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonButton expand="block"routerLink={'/'}>Go Back</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPW;
