import { Plugins } from '@capacitor/core';
import { getAzConfigSettings } from '../util/az-tools';

const fetch = require('superfetch');
const { Storage } = Plugins;

let API_ROOT = "";

async function getTokenObject() {
  const ret = await Storage.get({ key: 'token' });
  const token = ret.value;
  return token
}

async function getUserObject() {
  const ret = await Storage.get({ key: 'user' });
  const user = JSON.parse(ret.value);
  return user
}

async function setUserObject(userPayload) {
  await Storage.set({
    key: 'user',
    value: JSON.stringify(userPayload)
  });
}

export async function setApiRoot(apiRoot) {
  API_ROOT = apiRoot;
}

export async function getApiRoot() {
  return API_ROOT;
}

async function setTokenObject(token) {
  await Storage.set({
    key: 'token',
    value: JSON.stringify(token)
  });
}

export async function verifyUser(email, verify_key) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];

  var call = fetch.post.option({ encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/verify/', { email:email, verify_key:verify_key })

  return call;
};

export async function fetchUser(username, password) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchUser() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/auth/login/', { username:username, password:password })

  return call;
};

export async function createUserSubscription(planId) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: createUserSubscription() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/subscribe/', { planId:planId })

  return call;
};

export async function createAudit(auditCreateList, audit_name) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: createAudit() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/audits/', { auditCreateList:auditCreateList, audit_name:audit_name })

  return call;
};

export async function updateAudit(auditPayload) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: updateAudit() AZ Object ===> ", azConfig);

  var call = fetch.patch.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/audits/', auditPayload)

  return call;
};


export async function updateMainAudit(auditPayload) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: updateMainAudit() AZ Object ===> ", azConfig);

  var call = fetch.patch.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/audits-main/', auditPayload)

  return call;
};


export async function fetchAudits() {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchAudits() AZ Object  ===> ", azConfig);

  var call = fetch.get.option({ encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Authorization': 'JWT ' + token,
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/audits/')

  return call;
};

export async function fetchAuditDetail(uniqueID) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchAuditDetail() AZ Object ===> ", azConfig);

  var call = fetch.get.option({ encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Authorization': 'JWT ' + token,
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/audit-detail/', { uniqueID:uniqueID })

  return call;
};

export async function fetchAuditQueueDetail(uniqueID) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchAuditQueueDetail() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Authorization': 'JWT ' + token,
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/queue/', { uniqueID:uniqueID })

  return call;
};



export async function fetchDashboard() {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchDashboard() AZ Object ===> ", azConfig);

  var call = fetch.get.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Authorization': 'JWT ' + token,
    'Content-Type': "application/json",
    'Accept': 'application/json',
    },
  })(API_ROOT+'/dashboard/')

  return call;
};

export async function fetchAuditTypes() {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchAuditTypes() AZ Object ===> ", azConfig);

  var call = fetch.get.option({withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Authorization': 'JWT ' + token,
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/audit-types/')

  return call;
};

export async function fetchPortal() {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: fetchPortal() AZ Object ===> ", azConfig);

  var call = fetch.get.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Authorization': 'JWT ' + token,
    'Content-Type': "application/json",
    'Accept': 'application/json'
    },
  })(API_ROOT+'/portal/')

  return call;
};

export async function createReport(uniqueID) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: createReport() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/reports/', { uniqueID:uniqueID })

  return call;
};

export async function createSupportTicket(payload) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: createSupportTicket() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/contact/', { first_name:payload.first_name, last_name:payload.last_name, company:payload.company, email: payload.email, subject:payload.subject, message:payload.message })

  return call;
};

export async function createUser(payload) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: createUser() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ encoding: 'utf8', headers: {
      'User-Agent': 'request',
      'Content-Type': "application/json",
      'Accept': 'application/json'
      },
    })(API_ROOT+'/auth/register/', { first_name:payload.first_name, last_name:payload.last_name, username:payload.username, email: payload.email, password1:payload.password1, password2:payload.password2, company_name:payload.company_name, companyType:payload.companyType, employee_pin:payload.employee_pin })

  return call;
};

export async function updateUser(userPayload) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: updateUser() AZ Object ===> ", azConfig);

  var call = fetch.patch.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/auth/user/', userPayload)

  return call;
};

export async function updatePassword(password) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: updatePassword() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/auth/password/change/', password)

  return call;
};

export async function resetPassword(email) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: resetPassword() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/auth/password/reset/', email)

  return call;
};

export async function changePassword(password) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: changePassword() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ withCredentials: true, encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/auth/password/change/', password)

  return call;
};


export async function resetPasswordConfirm(password) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call

  var token = await getTokenObject()
  const azConfig = await getAzConfigSettings();
  API_ROOT = azConfig['API_ROOT'];
  //console.log("ML Diagnostic ==> sags/api: resetPasswordConfirm() AZ Object ===> ", azConfig);

  var call = fetch.post.option({ encoding: 'utf8', headers: {
    'User-Agent': 'request',
    'Content-Type': "application/json",
    'Authorization': 'JWT ' + token,
    'Accept': 'application/json'
    },
  })(API_ROOT+'/auth/password/reset/confirm/', password)

  return call;
};
