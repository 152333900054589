import {  IonGrid, IonRow, IonCol, IonBadge, IonSearchbar, IonTextarea, IonSlides, IonSlide, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect, useRef, useReducer} from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Audits.css';
import { connect, useDispatch } from 'react-redux';
import { useLocation, RouteComponentProps } from 'react-router-dom';

import { auditGetRequest, auditReportRequest, updateMainAuditRequest} from '../../actions/audits';
import {Bar} from 'react-chartjs-2';
import TreeMap from "react-d3-treemap";
// Include its styles in you build process as well
import "react-d3-treemap/dist/react.d3.treemap.css";
import { scaleSequential } from "d3-scale";
import * as chromatic from "d3-scale-chromatic";
import { Chart } from "react-google-charts";


import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
var slugify = require('slugify')



interface IProps {
  overview: boolean,
  set_overview: any,
  activeAuditObject: any,
}

export const AuditReportOverview: React.FC<IProps> = ({activeAuditObject, set_overview, overview}) => {

  const { name } = useParams<{ name: string; }>();
  const [searchText, setSearchText] = useState('');
  const [audits, set_audits] = useState<any[]>([]);
  const [barData, set_barData] = useState<any>([]);

  const [topicsList, set_topicsList] = useState<any>([{topic:"No Topics", value:0}]);

  //const [activeAuditObject, set_activeAuditObject] = useState('');
  const [reportURL, set_reportURL] = useState('');

  const [activeRedirect, set_activeRedirect] = useState(false);

  const [compliancePerc, set_compliancePerc] = useState(0);

  const [totalFindingCount, set_totalFindingCount] = useState(0);
  const [criticalCount, set_criticalCount] = useState(0);
  const [majorCount, set_majorCount] = useState(0);
  const [minorCount, set_minorCount] = useState(0);
  const [numberOfRecommendations, set_numberOfRecommendations] = useState(0);

  const treeMapRef = useRef<any>(null);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [editAuditActive,setEditAuditActive] = useState(false);
  const [executiveSummaryText, setExecutiveSummaryText] = useState('');
  const [auditNameText, setAuditNameText] = useState('');
  const [authorNameText, setAuthorNameText] = useState('');
  const [positivesText, setPositivesText] = useState('');
  const [negativesText, setNegativesText] = useState('');


  const [overviewText, set_overviewText] = useState('');
  const [site_overview, set_site_overview] = useState('');
  const [org_overview, set_org_overview] = useState('');
  const [audit_overview, set_audit_overview] = useState('');
  const [audit_actions, set_audit_actions] = useState('');
  const [addendum_text, set_addendum_text] = useState('');


  const dispatch = useDispatch();
  const location = useLocation();

  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  function renderLabelList(sets:any) {
    var renderList = []
    for(var i=0; i< sets.length; i++){
      renderList.push(sets[i].topic)
    }

    return renderList
  }

  function renderBarGraphDataList(sets:any) {
    var renderList:Array<any>  = []
    renderList.push(['Topic', 'Count'])

    for(var i=0; i< sets.length; i++){
      //console.log(sets[i].count)
      renderList.push([sets[i].topic, sets[i].count])
    }


    return renderList
  }

  function renderPercent(num:number) {
    return num.toFixed(2)
  }


  useEffect(() => {

     setAuditNameText(activeAuditObject.name)
     setAuthorNameText(activeAuditObject.executive_summary)
     setNegativesText(activeAuditObject.negatives)
     setPositivesText(activeAuditObject.positives)

     set_overviewText(activeAuditObject.overview);
     set_site_overview(activeAuditObject.site_overview);
     set_org_overview(activeAuditObject.org_overview);
     set_audit_overview(activeAuditObject.audit_overview);
     set_audit_actions(activeAuditObject.audit_actions);
     set_addendum_text(activeAuditObject.addendum_text);

     if(activeAuditObject.executive_summary=== null || activeAuditObject.executive_summary === undefined){
       setExecutiveSummaryText('No executive summary written yet.')
     }else{
       setExecutiveSummaryText(activeAuditObject.executive_summary)
     }

     if(globalState.report_url.report_url !== ''){
       set_reportURL(globalState.report_url.report_url)
     }

     if(globalState.auditList !== null){
       var data = JSON.parse(globalState.auditList)
       var auditsParsed = data.auditStores
       if(activeAuditObject.size !== 0){
         let filteredList: Array<any> = [];
         for(var i=0; i < auditsParsed.length; i++){
            if(activeAuditObject.name === auditsParsed[i].audit_name){
              //console.log("Yup!");
              filteredList.push(auditsParsed[i])
            }
         }
         set_audits(filteredList)

         if(filteredList.length > 0){
           const critCount = filteredList.filter((item:any) => item.classification_of_gaps_non_conformance === "5");
           const majCount = filteredList.filter((item:any) => item.classification_of_gaps_non_conformance === "4");
           const minCount = filteredList.filter((item:any) => item.classification_of_gaps_non_conformance === "3");
           const recCount = filteredList.filter((item:any) => item.classification_of_gaps_non_conformance === "2");
           const findingsList = filteredList.filter((item:any) => parseInt(item.classification_of_gaps_non_conformance) > 2);
           set_criticalCount(critCount.length)
           set_majorCount(majCount.length)
           set_minorCount(minCount.length)
           set_numberOfRecommendations(recCount.length)
           var findingTotal = critCount.length+majCount.length+minCount.length
           set_totalFindingCount(findingTotal)
           var progressPercent = ((findingTotal / filteredList.length) * 100)
           var newNum = 100 - progressPercent
           set_compliancePerc(newNum)
           const topicArray: Array<any> = [];
           for(var i=0; i < findingsList.length; i++){
             const currentCount = findingsList.filter((item:any) => item.topic.trim() === findingsList[i]["topic"].trim());
             if(currentCount.length > 0){
               const topicExists = topicArray.some((item:any) => item.topic.trim() === findingsList[i]["topic"].trim() )
               if(topicExists){
                 var foundIndex = topicArray.findIndex(x => x.topic == findingsList[i]["topic"]);
                 topicArray[foundIndex] =  {topic:findingsList[i]["topic"], count:currentCount.length};
               }else{
                 topicArray.push({topic:findingsList[i]["topic"], count:1})
               }
             }
           }
           set_topicsList(topicArray)
           if(topicArray.length> 0){
             var barDataFunc = renderBarGraphDataList(topicArray)
             set_barData(barDataFunc)
           }
         }
       }
     }
  },[globalState.auditList, activeAuditObject])

  useEffect(() => {
     if(globalState.report_url.report_url !== ''){
       set_reportURL(globalState.report_url.report_url)
     }
  },[globalState.report_url])

  useEffect(() => {
     if(globalState.report_url.report_url !== '' || globalState.report_url.report_url !== undefined){
       set_reportURL(globalState.report_url.report_url)
       if(activeRedirect === true){
          window.open(reportURL, '_self', 'location=yes')
          set_activeRedirect(false)
       }
     }

  })

  const slideOpts = {
    initialSlide: 1,
    speed: 400
  };

  function renderMatrixChildren(sets:any) {
    var renderList: Array<any> = []
    var finalList: Array<any> = []

    var set = null
    if(sets.length > 0){
      var legend = ['Finding','Topic','Count']
      var init = ['Finding', null, sets.length]
      finalList.push(legend)
      finalList.push(init)

      for(var i=0; i< sets.length; i++){
        finalList.push([sets[i].topic, 'Finding',sets[i].count])
      }

    }else{
      var legend: Array<string> = ['Finding','Topic','Count']
      var init: Array<any> = ['Finding', null, 0, ]
      finalList.push(legend)
      finalList.push(init)
    }
    return finalList
  }

  const data = {
  labels: renderLabelList(topicsList),
  datasets: [
    {
      label: 'Findings',
      backgroundColor: 'rgba(74,121,214,1)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 1,
      data: renderBarGraphDataList(topicsList)
    }
  ]
}

const data2 = {
    name: "Findings Matrix",
    children: [
      {
        name: "Production Controls",
        value: 10,
      },
      { name: "Management", value: 2 },
      { name: "Child 2", value: 1 },
      {
        name: "Child 1",
        children: [
          { name: "Child 1.1", value: 1 },
          { name: "Child 1.2", value: 5 },
          { name: "Child 1.3", value: 15 },
          { name: "Child 1.4", value: 25 },
        ],
      },
    ],
  };

  const rd3 = require('react-d3-library');

  function renderStatus(status:string) {
    if(status === "Done"){
      return(<IonBadge color="success">{status}</IonBadge>);
    }else if(status === "In Progress"){
      return(<IonBadge color="warning">{status}</IonBadge>)
    }else{
      return(<IonBadge color="light">{status}</IonBadge>)
    }
  }

  function calculate(item:any) {
    set_overview(false)
  }

  function handleReportCreate() {

    setTimeout(() => dispatch(auditReportRequest({uniqueID:activeAuditObject.uniqueID, audit_name: activeAuditObject.audit_name })), 10);

    setTimeout(() => set_activeRedirect(true), 4000);


  }

  function handleBackButton(item:any) {
    set_overview(false)
  }

  function truncateString(str: string, num:number) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  function renderTableItem(item: any, i:number) {
      return(
        <IonItem button routerLink={"/audits/"+slugify(item.audit_name)+"/"+item.uniqueID+"/"} detail={true} >
          <IonLabel><b>{i+1}: {item.audit_name}</b> | {item.regulation} | {truncateString(item.details, 60)} | </IonLabel>
          {renderStatus(item.status)}
        </IonItem>
        );
  }

  function renderTable(audits: any) {
    var data = audits
    var renderList = [];
    for(var i=0;i< data.length; i++){
      renderList.push(renderTableItem(data[i], i))
    }
    return(
      <IonList>
        {renderList}
      </IonList>
    )
  }

  const renderList = (audits:Array<any>) => (
      audits.map((audit:any) => (
       <IonRow>
         <IonCol>
           <IonLabel>1</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>FDA</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>Manangement</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>0.1HRC Sec. 123</IonLabel>
         </IonCol>
         <IonCol>
           <IonLabel>Blah blah blah..</IonLabel>
         </IonCol>
         <IonCol>
           <IonButton size="small"><IonIcon  icon={optionsOutline} /></IonButton>
           <IonButton size="small">View</IonButton>
         </IonCol>
       </IonRow>
    ))
  )

  interface TreeMapInPutData {
    name: string;
    value?: number;
    children?: Array<any>;
    className?: string;
  }

    function handleZoomOut(item:any, treeMapRef:any) {
      // if(treeMapRef !== undefined){
      //   console.log(treeMapRef)
      //   //treeMapRef.current.zoomOut()
      // }
    }

    function handleResetZoom(item:any, treeMapRef:any) {
      // if(treeMapRef !== undefined){
      //   treeMapRef.current.resetZoom()
      // }
    }

    function handleUpdateAudit(e:any, auditObject:any) {
      setTimeout(() => dispatch(updateMainAuditRequest({uniqueID:activeAuditObject.uniqueID, executive_summary: executiveSummaryText, positives:positivesText, negatives: negativesText, overview:overviewText, site_overview:site_overview, org_overview:org_overview, audit_overview:audit_overview, audit_actions:audit_actions, addendum_text:addendum_text })), 10);
      setEditAuditActive(!editAuditActive)
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{auditNameText}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{auditNameText}</IonTitle>

          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size={'3'}>
                <IonButton color="" routerLink={"/audits/"+slugify(activeAuditObject.name)} size="large" onClick={(e:any) => handleBackButton(e)} >Back</IonButton>
            </IonCol>
            <IonCol>
              <IonRow>
                <IonCol>
                  <IonLabel>Audit ID: {activeAuditObject.uniqueID || ''}</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Status: {renderStatus(activeAuditObject.status) || ''}</IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol>
              <IonRow>
                <IonCol>
                  <IonLabel><IonButton color="success" size="large" onClick={() => handleReportCreate()}>Download Report</IonButton></IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Executive Summary</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel>Audit Name: <b>{auditNameText || ''}</b></IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>Auditor: <b>{activeAuditObject.user.first_name +' '+ activeAuditObject.user.last_name || ''}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Audit Type: <b>{audits.length > 0 ? audits[0].regulation : ''}</b></IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Total Findings: <b>{totalFindingCount}</b></IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>Compliance Percentage: <b>{renderPercent(compliancePerc) || 0}%</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Executive Summary:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={executiveSummaryText} onIonChange={e => setExecutiveSummaryText(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{executiveSummaryText}</b></IonLabel>
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Positives:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={positivesText} onIonChange={e => setPositivesText(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{positivesText}</b></IonLabel>
                }
              </IonCol>
              <IonCol>
                <IonLabel>Negatives:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={negativesText} onIonChange={e => setNegativesText(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{negativesText}</b></IonLabel>
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Report Overview:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={overviewText} onIonChange={e => set_overviewText(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{overviewText}</b></IonLabel>
                }
              </IonCol>
              <IonCol>
                <IonLabel>Site Overview:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={site_overview} onIonChange={e => set_site_overview(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{site_overview}</b></IonLabel>
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Organizational Overview:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={org_overview} onIonChange={e => set_org_overview(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{org_overview}</b></IonLabel>
                }
              </IonCol>
              <IonCol>
                <IonLabel>Audit Overview:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={audit_overview} onIonChange={e => set_audit_overview(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{audit_overview}</b></IonLabel>
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Audit Actions:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={audit_actions} onIonChange={e => set_audit_actions(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{audit_actions}</b></IonLabel>
                }
              </IonCol>
              <IonCol>
                <IonLabel>Addendum:</IonLabel>
                {editAuditActive
                ? <IonTextarea value={addendum_text} onIonChange={e => set_addendum_text(e.detail.value!)}></IonTextarea>
                : <IonLabel> <b>{addendum_text}</b></IonLabel>
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {editAuditActive
                ? <><IonButton color="success" size="small" onClick={(e) => handleUpdateAudit(e, activeAuditObject)}>Save</IonButton><IonButton color="danger" size="small" onClick={(e) => setEditAuditActive(false)}>Cancel</IonButton></>
                : <IonButton color="primary" size="small" onClick={() => setEditAuditActive(!editAuditActive)}>Edit</IonButton>
                }
              </IonCol>
            </IonRow>
          </IonGrid>

          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Findings</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel>Classification</IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel># of Findings</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Critical</IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel><b>{criticalCount}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Major</IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel><b>{majorCount}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Minor</IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel><b>{minorCount}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Recommendations</IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel><b>{numberOfRecommendations}</b></IonLabel>
              </IonCol>
            </IonRow>
            <br />
            {topicsList.length > 0
            ? (
            <Chart
              width={'100%'}
              height={'300px'}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={barData}
              options={{
                title: '# of Findings',
              }}
              rootProps={{ 'data-testid': '1' }}
            />)
            :(<IonLabel>No findings found.</IonLabel>)
            }

          </IonGrid>

          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Report Matrix</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
          <IonGrid>
            {topicsList.length > 0
            ? (
              <Chart
                  ref={treeMapRef}
                  width={'100%'}
                  height={'300px'}
                  chartType="TreeMap"
                  loader={<IonLabel>Loading tree map.</IonLabel>}
                  data={
                  renderMatrixChildren(topicsList)
                  }
                  options={{
                    minColor: '#0d0',
                    midColor: '#ddd',
                    maxColor: '#f00',
                    headerHeight: 30,
                    fontColor: 'black',
                    showScale: true,
                    responsive: true,

                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
            )
            : (<IonLabel>No findings found.</IonLabel>)
            }

          </IonGrid>

          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default connect((props: any) => ({
     login: props.auth
}))(AuditReportOverview);
