import {  IonGrid, IonRow, IonProgressBar, IonTextarea, IonSlides, IonSlide ,IonCol, IonBadge, IonSearchbar, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonListHeader, IonItemDivider, IonCardSubtitle, IonCardTitle, IonSelect, IonSelectOption,IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Audits.css';
import { connect, useDispatch } from 'react-redux';
import { auditGetRequest, createAuditRequest, updateAuditRequest} from '../../actions/audits';
import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { CreateAnimation } from "@ionic/react";
import { useLocation, RouteComponentProps } from 'react-router-dom';
var slugify = require('slugify')


interface AuditDetailPageProps extends RouteComponentProps<{
  slug: string;
  id: string;
}> {}

const AuditStoreDetail: React.FC<AuditDetailPageProps> = ({match}) => {

  const { name } = useParams<{ name: string; }>();
  const [audit_name, set_audit_name] = useState('');
  const [audits, set_audits] = useState([]);
  const dispatch = useDispatch();
  let { slug, id } = useParams<{ slug: string, id: string }>();
  const [progressPercent, set_progressPercent] = useState<any>(0);


  const [queueStarted, set_queueStarted] = useState(true);

  //
  const [item, set_item] = useState({audit_name:"",regulation:"", section:"", uniqueID:"", status:"", topic:"", details:"",index:0});
  const [procedures_reviewed, set_procedures_reviewed] = useState('');
  const [procedures_gaps, set_procedures_gaps] = useState('');
  const [quality_records_reviewed, set_quality_records_reviewed] = useState('');
  const [quality_records_gaps, set_quality_records_gaps] = useState('');
  const [classification_of_gaps_non_conformance, set_classification_of_gaps_non_conformance] = useState(1);
  const [comments, set_comments] = useState('');

  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  const slideOpts = {
    initialSlide: 1,
    speed: 400
  };



  function loadForm(item:any) {
    //console.log(item.classification_of_gaps_non_conformance)
    set_item({audit_name:item.audit_name,regulation:item.regulation, section:item.section, uniqueID:item.uniqueID, status:item.status, topic:item.topic, details:item.details,index:0})
    set_procedures_reviewed(item.procedures_reviewed);
    set_procedures_gaps(item.gaps_found_during_review_of_procedures);
    set_quality_records_reviewed(item.quality_records_reviewed);
    set_quality_records_gaps(item.gaps_found_during_review_of_quality_records);
    set_classification_of_gaps_non_conformance(parseInt(item.classification_of_gaps_non_conformance));
    set_comments(item.comments);
  }


  function resetForm() {
    set_item({audit_name:"",regulation:"", section:"", uniqueID:"", status:"", topic:"", details:"",index:0})
    set_procedures_reviewed('');
    set_procedures_gaps('');
    set_quality_records_reviewed('');
    set_quality_records_gaps('');
    set_classification_of_gaps_non_conformance(1);
    set_comments('');
  }

  function renderPercent(num:number) {
    return num.toFixed(2)
  }




  // useEffect(() => {
  //    setTimeout(() => dispatch(loginRequest({username:"ccr", password:"admin"})), 2000);
  // },[])

  // useEffect(() => {
  //    setTimeout(() => dispatch(auditGetRequest()), 10);
  //    set_audits(globalState.auditList)
  //    //console.log(globalState.auditList)
  // },[globalState.auditList])

    useEffect(() => {
        //console.log(id)
       //setTimeout(() => dispatch(auditGetRequest()), 10);
       if(globalState.auditList !== null){
         var data = JSON.parse(globalState.auditList)
         var auditsParsed = data.auditStores
         //console.log(id)
         //console.log(activeAuditObject);
         var total = 0
         var numCompleted = 0;

         if(auditsParsed.length > 0){
           let filteredList: Array<any> = [];

           for(var i=0; i < auditsParsed.length; i++){


              if(id === auditsParsed[i].uniqueID){
                //console.log("Yup!");
                const allAudits = auditsParsed.filter((item:any) => item.audit_name === auditsParsed[i].audit_name);

                var activeAuditObject = auditsParsed[i]
                //set_item(auditsParsed[i])
                loadForm(auditsParsed[i])
                //filteredList.push(auditsParsed[i])

                for(var k=0; k < allAudits.length; k++){
                  if(allAudits[k].status === "Done"){
                    numCompleted = numCompleted+1
                  }
                  total = total+1
                }

              }
           }
           var progressPercent = ((numCompleted / total) * 100)
           set_progressPercent(progressPercent)
           //set_audits(filteredList)
         }

       }else{
         setTimeout(() => dispatch(auditGetRequest()), 10);
       }
    },[globalState.auditList, id])

    function handleAuditCreate(e:any) {
         //dispatch(createAuditRequest({regulation:audits, audit_name:audit_name}))
    }

    function handleAuditSave(e:any) {
      //console.log(e.id)
      dispatch(updateAuditRequest({uniqueID:e.uniqueID, procedures_reviewed: procedures_reviewed, classification_of_gaps_non_conformance: classification_of_gaps_non_conformance, quality_records_reviewed: quality_records_reviewed, gaps_found_during_review_of_quality_records: quality_records_gaps,gaps_found_during_review_of_procedures:procedures_gaps,comments:comments, status: "In Progress" }))
    }

    function handleAuditMarkDone(e:any) {
      dispatch(updateAuditRequest({uniqueID:e.uniqueID, procedures_reviewed: procedures_reviewed, gaps_found_during_review_of_procedures: procedures_gaps,quality_records_reviewed: quality_records_reviewed,gaps_found_during_review_of_quality_records: quality_records_gaps,
        classification_of_gaps_non_conformance: classification_of_gaps_non_conformance, comments: comments,
        status: "Done" }))
    }



    function handleSlideChange(e:any) {

    }

    function toggleQueueScreens() {
      set_queueStarted(!queueStarted)
    }

    function renderStatus(status:string) {
      if(status === "Done"){
        return(<IonBadge color="success">{status}</IonBadge>);
      }else if(status === "In Progress"){
        return(<IonBadge color="warning">{status}</IonBadge>)
      }else{
        return(<IonBadge color="light">{status}</IonBadge>)
      }
    }




    function getUniqueListBy(arr:any, key:any) {
      return [...new Map(arr.map((item:any) => [item[key], item])).values()]
    }

    function renderTableItem(item: any, index:number) {
        return(
            <IonSlide>
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>Total Audit Completion: <b>%20</b></IonLabel>
                    <br />
                    <IonProgressBar value={0.9} ></IonProgressBar>
                  </IonCol>
                </IonRow>
                <br />
                <IonRow>
                  <IonCol>
                    <IonTitle><b>{item.audit_name}</b></IonTitle>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Case ID: <b>{item.uniqueID}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {renderStatus(item.status)}
                  </IonCol>
                </IonRow>
                <br />
                <IonRow>
                  <IonCol>
                    <IonLabel>Regulation: <b>{item.regulation}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Section: <b>{item.section}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Topic: <b>{item.topic}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonTextarea placeholder="Procedures Reviewed..." value={procedures_reviewed} onIonChange={e => set_procedures_reviewed(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Procedure(s ) Gaps Review..." value={procedures_gaps} onIonChange={e => set_procedures_gaps(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Quality Record(s) Reviewed..." value={quality_records_reviewed} onIonChange={e => set_quality_records_reviewed(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Quality Record(s) Gaps..." value={quality_records_gaps} onIonChange={e => set_quality_records_gaps(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Comments..." value={comments} onIonChange={e => set_comments(e.detail.value!)}></IonTextarea>
                    <br />
                    <IonLabel>Classification of Gap(s) found:</IonLabel>
                    <br />
                    <IonSelect value={classification_of_gaps_non_conformance} onIonChange={e => {set_classification_of_gaps_non_conformance(e.detail.value!)}} placeholder="Select a classification...">
                      <IonSelectOption value={1}>1 - N/A, Meets Regulation/Requirement</IonSelectOption>
                      <IonSelectOption value={2}>2 - Needs Improvement/Recommendations</IonSelectOption>
                      <IonSelectOption value={3}>3 - LOW Nonconformance</IonSelectOption>
                      <IonSelectOption value={4}>4 - MAJOR Nonconformance</IonSelectOption>
                      <IonSelectOption value={5}>5 - HIGH Nonconformance</IonSelectOption>
                    </IonSelect>
                    <br />
                    <IonButton color="dark" size="large" onClick={ () => handleAuditSave(item)}>Save</IonButton>
                    <IonButton color="success" size="large" onClick={ () => handleAuditMarkDone(item)}>Mark Complete</IonButton>
                    <IonButton size="large" onClick={ () => toggleQueueScreens()}>End Queue</IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonSlide>
          );
    }


    function renderQueueSlides(audits: any) {
      var data = JSON.parse(audits)

      //console.log(data)

      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderTableItem(data[i], i))
      }
      return(
        <>
        <CreateAnimation
          duration={500}
          fromTo={{
            property: "opacity",
            fromValue: "0",
            toValue: `1`,
          }}
          easing="ease-in"
          play={true}
        >
          <IonSlides pager={true} options={slideOpts} onIonSlideDidChange	={() => console.log('slide change')}>
              {renderList}
          </IonSlides>
        </CreateAnimation>
        </>
      )
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Audit Details</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{item.audit_name}</IonTitle>
          </IonToolbar>
        </IonHeader>
          <IonSlides pager={true} options={slideOpts} onIonSlideDidChange	={() => console.log('slide change')}>
          <IonSlide>
            <IonCol>
              <br />
              <IonRow>
                <IonCol>
                  <IonTitle><b>{item.audit_name}</b></IonTitle>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Case ID: <b>{item.uniqueID}</b></IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {renderStatus(item.status)}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Audit Percentage Complete: <b>{renderPercent(progressPercent)} %</b></IonLabel>
                  <IonProgressBar value={progressPercent/100} ></IonProgressBar>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol>
                  <IonLabel>Regulation: <b>{item.regulation}</b></IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Section: <b>{item.section}</b></IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Topic: <b>{item.topic}</b></IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Details: <b>{item.details}</b></IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonTextarea placeholder="Procedures Reviewed..." value={procedures_reviewed} onIonChange={e => set_procedures_reviewed(e.detail.value!)}></IonTextarea>
                  <IonTextarea placeholder="Procedure(s ) Review Gaps ..." value={procedures_gaps} onIonChange={e => set_procedures_gaps(e.detail.value!)}></IonTextarea>
                  <IonTextarea placeholder="Quality Record(s) Reviewed..." value={quality_records_reviewed} onIonChange={e => set_quality_records_reviewed(e.detail.value!)}></IonTextarea>
                  <IonTextarea placeholder="Quality Record(s) Gaps..." value={quality_records_gaps} onIonChange={e => set_quality_records_gaps(e.detail.value!)}></IonTextarea>
                  <IonTextarea placeholder="Comments..." value={comments} onIonChange={e => set_comments(e.detail.value!)}></IonTextarea>
                  <br />
                  <IonLabel>Classification of Gap(s) found:</IonLabel>
                  <br />
                  <IonSelect value={classification_of_gaps_non_conformance} onIonChange={e => {set_classification_of_gaps_non_conformance(e.detail.value!)}} placeholder="Select a classification...">
                    <IonSelectOption value={1}>1 - N/A, Meets Regulation</IonSelectOption>
                    <IonSelectOption value={2}>2 - Recommendation</IonSelectOption>
                    <IonSelectOption value={3}>3 - MINOR Finding</IonSelectOption>
                    <IonSelectOption value={4}>4 - MAJOR Finding</IonSelectOption>
                    <IonSelectOption value={5}>5 - CRITICAL Finding</IonSelectOption>
                  </IonSelect>
                  <br />
                  <IonButton color="dark" size="large" onClick={ () => handleAuditSave(item)}>Save</IonButton>
                  <IonButton color="success" size="large" onClick={ () => handleAuditMarkDone(item)}>Mark Complete & Save</IonButton>
                  <IonButton size="large" routerLink={"/audits/"+slugify(item.audit_name)+"/"}>Back</IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonSlide>
          </IonSlides>
      </IonContent>
      </IonPage>

  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(AuditStoreDetail);
