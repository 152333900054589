import {  IonGrid, IonSpinner, IonModal, IonSelect, IonSelectOption, IonImg, useIonToast, IonRow, IonCol, IonBadge, IonSearchbar, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { home, libraryOutline, peopleCircleOutline, helpBuoy, layersOutline, colorWandOutline, refreshOutline, archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Home.css';
import { connect, useDispatch } from 'react-redux';

import { loginRequest, apiDiagnostic, userVerificationRequest } from '../../actions/auth';
import { dashboardGetRequest, planRequest } from '../../actions/audits';
import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { RouteComponentProps, useHistory } from "react-router-dom";
import {XYPlot, XAxis, YAxis, RadialChart, LabelSeries} from 'react-vis';
import { CreateAnimation } from "@ionic/react";
import { PieChart, Pie, ResponsiveContainer} from 'recharts';
import { Doughnut } from 'react-chartjs-2';
var slugify = require('slugify');





interface Props extends RouteComponentProps<{}> {
  history:any,
  location:any,
  match:any,
}

const Home: React.FC = (props) => {
  const { email, verify_key } = useParams<{ email: string; verify_key: string; }>();
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [verifySent, set_VerifySent] = useState(false);


  const [dashboardData, set_dashboardData] = useState<any[]>([]);
  const [stripePlans, set_stripePlans] = useState<any[]>([]);
  const [selectedPlan, set_selectedPlan] = useState('');

  const [platformUpdates, set_platformUpdates] = useState<any[]>([]);
  const [planInfo, set_planInfo] = useState<any[]>([]);
  const [initialLoad, set_initialLoad] = useState(true);


  const [auditsInProgress, set_auditsInProgress] = useState(0);
  const [auditsNotStarted, set_auditsNotStarted] = useState(0);
  const [auditsDone, set_auditsDone] = useState(0);

  const [graphData, set_graphData] = useState({datasets:[{
      data: [0,0,0],
      backgroundColor: [
       'rgba(45, 211, 111, 1)',
       'rgba(24, 6, 185, 1)',
       'rgba(244, 245, 248, 1)',
     ],
    }],labels: [
      'Audits Completed',
      'Audits In Progress',
      'Audits Not Started'
  ]});



  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  const selectGlobalApp = (state: RootState) => state.global
  const globalStateApp = useSelector(selectGlobalApp);
  const [present, dismiss] = useIonToast();

  const plans = [
    { planID: 'price_1IXte8EIHXMrWlo1BxnQmDVs', planName: 'Single User Plan Monthly' },
    { planID: 'price_1IXte8EIHXMrWlo1msbrubZt', planName: 'Single User Plan Yearly' },
    { planID: 'price_1IXtfMEIHXMrWlo1XfGgEzm1', planName: 'Business Plan Monthly' },
    { planID: 'price_1IXtfMEIHXMrWlo1jRAddYpn', planName: 'Business Plan Yearly' },
    { planID: 'price_1JFNdREIHXMrWlo1xBBu3k9T', planName: 'Base Plan Yearly' },
    { planID: 'price_1JFNctEIHXMrWlo1nWihlzWF', planName: 'Base Plan Monthly' },
    { planID: 'price_1JFRUXEIHXMrWlo1kmTGq7gG', planName: 'Base Plan Yearly' },
    { planID: 'price_1JFRUdEIHXMrWlo1xYu6UpXW', planName: 'Base Plan Monthly' }
  ];

  useEffect(() => {
     if(globalState.dashboardData !== null){
       var resp = globalState.dashboardData
       var recent = resp.recentAudits;
       var stripe = resp.stripeData;
       var updates = resp.updates;
       var plans = resp.plans.data;

       var tempNotStartedCount = 0;
       var tempInProgressCount = 0;
       var tempDoneCount = 0
       for(var i=0; i < recent.length; i++){
         if(recent[i].status === "Not Started"){
           tempNotStartedCount = tempNotStartedCount + 1
         }else if(recent[i].status === "In Progress"){
           tempInProgressCount= tempInProgressCount + 1
         }else if(recent[i].status === "Done"){
           tempDoneCount = tempDoneCount + 1
         }

       }

       set_auditsInProgress(tempInProgressCount)
       set_auditsNotStarted(tempNotStartedCount)
       set_auditsDone(tempDoneCount)
       set_platformUpdates(updates)
       set_stripePlans(plans)
       set_graphData({datasets:[{
           data: [auditsDone,auditsInProgress,auditsNotStarted],
           backgroundColor: [
            'rgba(45, 211, 111, 1)',
            'rgba(24, 6, 185, 1)',
            'rgba(244, 245, 248, 1)',
          ],
         }],labels: [
           'Audits Completed',
           'Audits In Progress',
           'Audits Not Started'
       ]});

       set_dashboardData(recent)
       set_planInfo(stripe.data)
     }else{
       dispatch(dashboardGetRequest());
     }

  },[globalState.dashboardData, dashboardData])

  useEffect(() => {

      if(globalStateApp.showMsg === true && globalStateApp.alertMsg !== null ){
          present(globalStateApp.alertMsg, 3000)
      }

  },[globalStateApp.alertMsg, globalStateApp.showMsg])

  useEffect(() => {

      if(globalState.dashboardData !== null && globalState.dashboardData.stripeData.data.length === 0 ){
          setShowModal(true)
      }

  },[globalState.dashboardData])

  useEffect(() => {
     if(globalStateApp.redirect_url !== null){
       setTimeout(() =>window.open(globalStateApp.redirect_url, '_self'), 2000);
     }
  },[globalStateApp.redirect_url])

  useEffect(() => {
     if(email != null && verify_key !=null && !verifySent){
       dispatch(userVerificationRequest({email:email, verify_key:verify_key}));
       set_VerifySent(true);
     }
  },[email, verify_key, verifySent]);

  const data01 = {
    datasets: [{
        data: [auditsDone, auditsInProgress, auditsDone],
        backgroundColor: [
         'rgba(45, 211, 111, 1)',
         'rgba(24, 6, 185, 1)',
         'rgba(244, 245, 248, 1)',
       ],
      }],
    labels: [
        'Audits Completed',
        'Audits In Progress',
        'Audits Not Started'
    ],
  };


  function renderStatus(status:string) {
    if(status === "Done"){
      return(<IonBadge color="success">{status}</IonBadge>);
    }else if(status === "In Progress"){
      return(<IonBadge color="warning">{status}</IonBadge>)
    }else{
      return(<IonBadge color="light">{status}</IonBadge>)
      }
    }

    function renderTableItem(item: any) {
        return(
            <IonItem button routerLink={"/audits/"+slugify(item.name)}  detail={true} >
              <IonLabel>{item.name} {item.regulation}</IonLabel>
              {renderStatus(item.status)}
            </IonItem>
          );
    }

    function renderTable(data: any) {
      var renderList = [];

      let filteredItems = data.filter((item:any) => item.name && item.name.toLowerCase().includes(searchText.toLowerCase()));

      filteredItems = filteredItems.sort(function(a:any, b:any){
          return b.id - a.id;
      });


      for(var i=0;i< 5; i++){
        //console.log(data[i])
        if(filteredItems[i] !== undefined){
          renderList.push(renderTableItem(filteredItems[i]))

        }
      }
      return renderList
    }

    function renderSubTableItem(item: any) {
        var plan = {};
        var date = new Date(item.current_period_end * 1000);
        // Will display time in 10:30:23 format
        var formattedTime = date.toLocaleDateString()

        if(item.plan.id === plans[0].planID){
          plan = plans[0].planName
        }
        else if(item.plan.id === plans[1].planID){
          plan = plans[1].planName
        }
        else if(item.plan.id === plans[2].planID){
          plan = plans[2].planName
        }
        else if(item.plan.id === plans[3].planID){
          plan = plans[3].planName
        }
        else if(item.plan.id === plans[4].planID){
          plan = plans[4].planName
        }
        else if(item.plan.id === plans[5].planID){
          plan = plans[5].planName
        }else if(item.plan.id === plans[6].planID){
            plan = plans[6].planName
          }
        else if(item.plan.id === plans[7].planID){
            plan = plans[7].planName
        }else{
          plan = "No active plan."
        }
        return(
            <IonItem button>
              <IonLabel>{plan}</IonLabel>
              <IonLabel>{formattedTime}</IonLabel>
              {renderStatus(item.status)}
            </IonItem>
          );
    }

    function renderSubTable(data: any) {
      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderSubTableItem(data[i]))
      }
      return renderList
    }

    function renderUpdateItem(item: any) {
        return(
            <IonItem button>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>{item.name}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>{item.text}</IonLabel>
                  </IonCol>
                  <IonCol>
                    {renderStatus(item.status)}
                  </IonCol>
                </IonRow>
             </IonGrid>
           </IonItem>
          );
    }


    function renderPlatformUpdates(data: any) {
      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderUpdateItem(data[i]))
      }
      return renderList
    }

    const myData = [{angle: 5}, {angle: 5}, {angle: 2}]

    function handleAccountSelection(item: any) {
      dispatch(planRequest({planId:selectedPlan}))
    }


  // var fetch = require('superfetch');
  //
  // fetch.get.option({ encoding: 'utf8', headers: {
  //   'User-Agent': 'request',
  //   'Authorization':"Basic Y2NyOmFkbWlu",
  //   'Content-Type': "application/json",
  //   'Accept': 'application/json'
  //   },
  // })('http://127.0.0.1:8000/audit-types/').then(function (body: any) {
  //   console.log(body)
  //   // when the status code begins with "2", the response body will be returned.
  // }).catch(function (response : any) {
  //   // otherwise, the whole response(including headers) is returned.
  // });


  return (
    <IonPage>
      <IonModal isOpen={showModal} cssClass='popup2'>
        <IonGrid class="popup2">
          <br />
          <IonRow class="ion-padding ion-text-center">
            <IonCol size="12">

            </IonCol>
          </IonRow>
          <IonRow class="ion-padding ion-text-center">
            <IonCol size="12">
              <IonLabel color="dark" class="title">Select Account Plan</IonLabel>
            </IonCol>
          </IonRow>
          <br />
          <IonRow class="centered" >
            <IonCol size="12" class="ion-padding">
              <IonLabel color="dark">To enjoy the full benefits of the auditing platform, please select an account plan that bests fits your needs.<br/><br/></IonLabel>
            </IonCol>
            <IonCol size="12">
              <IonLabel color="dark">Start with a 7-day trial. Credit card information is required to start, feel free to cancel anytime.</IonLabel>
            </IonCol>
          </IonRow>
          <br />
          <IonRow style={{display:"flex", justifyContent:"center"}}>
            <IonCol class="ion-text-center">
              <IonLabel color="dark" className="ion-text-align-center"><b>Monthly Plan</b><br/><br/></IonLabel>
              <IonLabel color="dark">$149.99 / Month<br/><br/></IonLabel>

            </IonCol>
            <IonCol class="ion-text-center" >
              <IonLabel color="dark"><b>Annual Plan</b><br/><br/></IonLabel>
              <IonLabel color="dark">$1699.99 / Year<br/><br/></IonLabel>
            </IonCol>

           </IonRow>
           <IonRow>
             <IonCol class="ion-padding ion-text-center">
               <IonSelect style={{backgroundColor:"white", borderRadius:25}} value={selectedPlan} placeholder="Select A Plan..." okText="Okay" cancelText="Dismiss" onIonChange={e => set_selectedPlan(e.detail.value)}>
                  {stripePlans.map(function(plan, idx){
                     if(plan.planID === "price_1JFNdREIHXMrWlo1xBBu3k9T"){
                       return (<IonSelectOption key={idx} value={plan.planID}>Base Plan Annually - $1699.99 / Year</IonSelectOption>)
                     }
                     else if(plan.planID === "price_1JFNctEIHXMrWlo1nWihlzWF"){
                       return (<IonSelectOption key={idx} value={plan.planID}>Base Plan Monthly - $149.99 / Month</IonSelectOption>)
                     }
                     else{return;}
                   })}
                </IonSelect>
             </IonCol>
           </IonRow>
           <IonButton expand="block" onClick={handleAccountSelection}>{globalStateApp.isLoading ? (<IonSpinner name="crescent" />) : `Subscribe` }</IonButton>

        </IonGrid>
      </IonModal>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Overview</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  {graphData !== null && dashboardData.length > 0
                    ? (<IonList><Doughnut data={graphData} /></IonList>)
                    : (<IonRow>
                       <IonCol>
                         <IonLabel>No audits found. New to BH Engineering? To get started, simply create a new audit. </IonLabel>
                       </IonCol>
                      </IonRow>)

                  }
                </IonCol>

              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Recent Activity</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonLabel>Most Recent Audits</IonLabel>
                      </IonCol>
                      <IonCol className="ion-align-self-end">
                        <IonLabel position="floating">Audit Status</IonLabel>
                      </IonCol>
                    </IonRow>
                    {dashboardData.length > 0 && dashboardData !== undefined
                      ? (<IonList>{renderTable(dashboardData)}</IonList>)
                      : (<IonRow>
                         <IonCol>
                           <IonLabel>No Audits found. </IonLabel>
                         </IonCol>
                        </IonRow>)

                    }
                  </IonCol>
                  <IonCol>
                    <IonLabel>Quickstarts</IonLabel>
                    <IonButton expand="block" routerLink={"/create"}><IonIcon slot="start" ios={colorWandOutline} md={colorWandOutline}/>Create New Audit</IonButton>
                    <IonButton expand="block" routerLink={"/queue"} ><IonIcon slot="start" ios={layersOutline} md={layersOutline}/>Start / Resume Audits</IonButton>
                    <IonButton expand="block" routerLink={"/audits"} ><IonIcon slot="start" ios={libraryOutline} md={libraryOutline}/>View My Audits</IonButton>
                    <IonButton expand="block" routerLink={"/support"} ><IonIcon slot="start" ios={helpBuoy} md={helpBuoy}/>Contact Support</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>My Plan</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonLabel>Plan</IonLabel>
                      </IonCol>
                      <IonCol className="ion-align-self-end">
                        <IonLabel position="floating">Due Date</IonLabel>
                      </IonCol>
                      <IonCol className="ion-align-self-end">
                        <IonLabel position="fixed">Status</IonLabel>
                      </IonCol>
                    </IonRow>
                    {planInfo !== null
                      ? (<IonList>
                          {planInfo.length > 0
                            ? renderSubTable(planInfo)
                            : <>You are currently not subscribed to a plan. Please add your billing details to continue enjoying the app.</>
                          }
                        </IonList>)
                      : (<IonRow>
                         <IonCol>
                           <IonLabel>1</IonLabel>
                         </IonCol>
                        </IonRow>)

                    }
                  </IonCol>
                </IonRow>
                <IonRow>
                <IonCol>
                  <IonButton expand="block" routerLink={"/account"}>Manage My Account</IonButton>
                </IonCol>
                </IonRow>
              </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>News/Blog</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  {platformUpdates.length > 0
                  ? (<>{renderPlatformUpdates(platformUpdates)}</>)
                  : (<IonLabel color="dark">No new platform updates at the moment. Check back later for additional updates.</IonLabel>)
                  }
                </IonCol>
              </IonRow>
              <IonButton expand="block" color="dark"  onClick={()=> {dispatch(dashboardGetRequest())}}>Refresh<IonIcon slot="start" ios={refreshOutline} md={refreshOutline}/></IonButton>

            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(Home);
