/* @flow */
import { actionGenerator } from './index';
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const GET_REQUEST = '@@audit/GET_REQUEST';
export const GET_SUCCESS = '@@audit/GET_SUCCESS';
export const GET_FAILURE = '@@audit/GET_FAILURE';

export const SUPPORT_REQUEST = '@@audit/SUPPORT_REQUEST';
export const SUPPORT_SUCCESS = '@@audit/SUPPORT_SUCCESS';
export const SUPPORT_FAILURE = '@@audit/SUPPORT_FAILURE';


export const GET_TYPE_REQUEST = '@@audit/GET_TYPE_REQUEST';
export const GET_TYPE_SUCCESS = '@@audit/GET_TYPE_SUCCESS';
export const GET_TYPE_FAILURE = '@@audit/GET_TYPE_FAILURE';

export const GET_DASHBOARD_REQUEST = '@@audit/GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = '@@audit/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = '@@audit/GET_DASHBOARD_FAILURE';

export const CREATE_AUDIT_REQUEST = '@@audit/CREATE_AUDIT_REQUEST';
export const CREATE_AUDIT_SUCCESS = '@@audit/CREATE_AUDIT_SUCCESS';
export const CREATE_AUDIT_FAILURE = '@@audit/CREATE_AUDIT_FAILURE';

export const UPDATE_MAIN_AUDIT_REQUEST = '@@audit/UPDATE_MAIN_AUDIT_REQUEST';
export const UPDATE_MAIN_AUDIT_SUCCESS = '@@audit/UPDATE_MAIN_AUDIT_SUCCESS';
export const UPDATE_MAIN_AUDIT_FAILURE = '@@audit/UPDATE_MAIN_AUDIT_FAILURE';


export const UPDATE_AUDIT_REQUEST = '@@audit/UPDATE_AUDIT_REQUEST';
export const UPDATE_AUDIT_SUCCESS = '@@audit/UPDATE_AUDIT_SUCCESS';
export const UPDATE_AUDIT_FAILURE = '@@audit/UPDATE_AUDIT_FAILURE';

export const AUDIT_REPORT_REQUEST = '@@audit/AUDIT_REPORT_REQUEST';
export const AUDIT_REPORT_SUCCESS = '@@audit/AUDIT_REPORT_SUCCESS';
export const AUDIT_REPORT_FAILURE = '@@audit/AUDIT_REPORT_FAILURE';

export const START_AUDITQUEUE_REQUEST = '@@audit/START_AUDITQUEUE_REQUEST';
export const START_AUDITQUEUE_SUCCESS = '@@audit/START_AUDITQUEUE_SUCCESS';
export const START_AUDITQUEUE_FAILURE = '@@audit/START_AUDITQUEUE_FAILURE';

export const PLAN_REQUEST = '@@audit/PLAN_REQUEST';
export const PLAN_REQUEST_FAILURE = '@@audit/PLAN_REQUEST_FAILURE';
export const PLAN_REQUEST_SUCCESS = '@@audit/PLAN_REQUEST_SUCCESS';

// export const REGISTER_REQUEST = '@@audit/REGISTER_REQUEST';
// export const REGISTER_SUCCESS = '@@audit/REGISTER_SUCCESS';
// export const REGISTER_FAILURE = '@@audit/REGISTER_FAILURE';

// First, create the thunk
// const fetchUserById = createAsyncThunk(
//   'users/LOGIN_REQUEST',
//   async (userId, thunkAPI) => {
//     const response = await userAPI.fetchById(userId)
//     return response.data
//   }
// )
//

interface UserObj {
  uniqueID: number,
  username: string,
  email: string,
  first_name: string,
  last_name: string
}

interface JWT {
  token: string;
  user: UserObj;
}

interface loginStruct {
  username: string;
  password: string;
}

interface auditPayloadStruct {
  auditCreateList: any;
  audit_name: string;
}

interface contactStruct {
  message: string,
  subject: string,
  email: string,
  first_name: string,
  last_name: string,
  company: string
}

interface ReportPayloadStruct {
  uniqueID: string;
  audit_name: string;
}

interface QueuePayloadStruct {
  uniqueID: string;
}


interface PlanSelectionStruct {
  planId: string;
}

export const auditGetRequest = createAction(GET_REQUEST);
export const auditGetTypeRequest = createAction(GET_TYPE_REQUEST);
export const dashboardGetRequest = createAction(GET_DASHBOARD_REQUEST);
export const createAuditRequest = createAction(CREATE_AUDIT_REQUEST, function prepare(
  create: auditPayloadStruct,
) {
  return {
    payload: create,
  };
});

export const updateMainAuditRequest = createAction(UPDATE_MAIN_AUDIT_REQUEST, function prepare(
  update: any,
) {
  return {
    payload: update,
  };
});

export const updateAuditRequest = createAction(UPDATE_AUDIT_REQUEST, function prepare(
  update: any,
) {
  return {
    payload: update,
  };
});

export const auditReportRequest = createAction(AUDIT_REPORT_REQUEST, function prepare(
  report: ReportPayloadStruct,
) {
  return {
    payload: report,
  };
});

export const supportRequest = createAction(SUPPORT_REQUEST, function prepare(
  contact: contactStruct,
) {
  return {
    payload: contact,
  };
});

export const queueRequest = createAction(START_AUDITQUEUE_REQUEST, function prepare(
  queue: QueuePayloadStruct,
) {
  return {
    payload: queue,
  };
});

export const planRequest = createAction(PLAN_REQUEST, function prepare(
  planId: PlanSelectionStruct,
) {
  return {
    payload: planId,
  };
});
