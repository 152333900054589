import {  IonGrid, IonRow, IonCol, IonModal, IonBadge, IonSearchbar, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox, IonTextarea, IonImg } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { helpBuoyOutline, layersOutline, colorWandOutline, refreshOutline, archiveOutline, archiveSharp, optionsOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Support.css';
import { connect, useDispatch } from 'react-redux';

import { loginRequest, portalGetRequest } from '../../actions/auth';

import { dashboardGetRequest, supportRequest } from '../../actions/audits';
import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { RouteComponentProps } from "react-router-dom";
import {XYPlot, XAxis, YAxis, RadialChart, LabelSeries} from 'react-vis';
import { CreateAnimation } from "@ionic/react";
import { PieChart, Pie, ResponsiveContainer} from 'recharts';

import {getTokenObject, getUserObject} from '../../sagas/auth';

interface Props extends RouteComponentProps<{}> {
  history:any,
  location:any,
  match:any,
}

const Support: React.FC = (props) => {

  const { name } = useParams<{ name: string; }>();
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  const [user, set_user] = React.useState({first_name:'-',last_name:'-',email:''});


  //User input fields
  const [company, set_company] = useState('');
  const [subject, set_subject] = useState('');
  const [message, set_message] = useState('');
  const [showModal, setShowModal] = useState(false);


  const [planInfo, set_planInfo] = useState({data:[]});


  const [portal, set_portal] = useState({url:""});
  const [link, set_link] = useState('');


  const [dashboardData, set_dashboardData] = useState([]);

  const selectGlobal = (state: RootState) => state.auth
  const globalState = useSelector(selectGlobal);

  const selectAuditData = (state: RootState) => state.audits
  const auditGlobalState = useSelector(selectAuditData);


  const data01 = [
  { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
  ];
  const data02 = [
    { name: 'A1', value: 100 },
    { name: 'A2', value: 300 },
    { name: 'B1', value: 100 },
    { name: 'B2', value: 80 },
    { name: 'B3', value: 40 },
    { name: 'B4', value: 30 },
    { name: 'B5', value: 50 },
    { name: 'C1', value: 100 },
    { name: 'C2', value: 200 },
    { name: 'D1', value: 150 },
    { name: 'D2', value: 50 },
  ];




  // useEffect(() => {
  //    setTimeout(() => dispatch(loginRequest({username:"ccr", password:"admin"})), 2000);
  // },[])

  useEffect(() => {

     async function fetchUser() {
        let fetchedUser = await getUserObject()
        return fetchedUser
      }

     var userObject = fetchUser()

     if(userObject !== null){
        userObject.then(value => {
          if(value !== null){
            set_user(value)
          }
          //console.log(value)
        })
     }

  },[])


  function renderStatus(status:string) {
    if(status === "Done"){
      return(<IonBadge color="success">{status}</IonBadge>);
    }else if(status === "In Progress"){
      return(<IonBadge color="warning">{status}</IonBadge>)
    }else{
      return(<IonBadge color="light">{status}</IonBadge>)
      }
    }

    function handleItemDetailLink(item:any) {

    }

    function handleSupportRequest(item:any) {
      setShowModal(true)
      setTimeout(() => dispatch(supportRequest({first_name:user.first_name, last_name:user.last_name, subject:subject, message:message, company:company,email:user.email})), 10);
      setTimeout(() => setShowModal(false), 6000);
    }

    function handleBillingPortalRequest(item:any) {
      setTimeout(() => window.open(link), 2000);
    }


    function renderTableItem(item: any) {
        return(
            <IonItem button onClick={() => {handleItemDetailLink(item)}} detail={true} >
              <IonLabel>{item.audit_name} : {item.regulation}</IonLabel>
              {renderStatus(item.status)}
            </IonItem>
          );
    }

    function renderTable(audits: any) {
      var data = JSON.parse(audits)
      var renderList = [];
      for(var i=0;i< data.length; i++){
        renderList.push(renderTableItem(data[i]))
      }
      return renderList
    }

    const myData = [{angle: 5}, {angle: 5}, {angle: 2}]



  // var fetch = require('superfetch');
  //
  // fetch.get.option({ encoding: 'utf8', headers: {
  //   'User-Agent': 'request',
  //   'Authorization':"Basic Y2NyOmFkbWlu",
  //   'Content-Type': "application/json",
  //   'Accept': 'application/json'
  //   },
  // })('http://127.0.0.1:8000/audit-types/').then(function (body: any) {
  //   console.log(body)
  //   // when the status code begins with "2", the response body will be returned.
  // }).catch(function (response : any) {
  //   // otherwise, the whole response(including headers) is returned.
  // });


  return (
    <IonPage>
    <IonModal isOpen={showModal} cssClass='popup'>
      <IonGrid>
        <br />
        <IonRow class="centered">
          <IonCol>
            <IonIcon color="light" class="support-icon" ios={helpBuoyOutline} md={helpBuoyOutline} />
          </IonCol>
        </IonRow>
        <br />
        <IonRow class="centered">
          <IonCol>
            <IonLabel color="light" class="title">Thank you</IonLabel>
          </IonCol>
        </IonRow>
        <br />
        <IonRow class="centered">
          <IonCol>
            <IonLabel color="light">A representative will contact you soon about your inquiry.</IonLabel>
          </IonCol>
        </IonRow>
        <br />
        <IonRow class="centered">
          <IonCol>
            <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Support</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Support</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Need Assistance?</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonRow class="centered">
                      <IonCol size="12" >
                        <IonIcon class="support-icon" ios={helpBuoyOutline} md={helpBuoyOutline} />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12">
                        <IonLabel>We are here to asisst you with all of your needs. Please fill out the form below with your inquiry and
                        our team will follow up.</IonLabel>
                      </IonCol>
                    </IonRow>
                    <br />
                    <IonRow>
                      <IonCol>
                      <IonLabel>Company Name</IonLabel>
                      <IonInput value={company} class="input-field-account" placeholder="Enter company name (if any)..." onIonChange={e => {set_company(e.detail.value!)}}></IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <IonLabel>Subject</IonLabel>
                      <IonInput value={subject} class="input-field-account" placeholder="Enter message subject..." onIonChange={e => {set_subject(e.detail.value!)}}></IonInput>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <IonLabel>Message</IonLabel>
                        <IonTextarea value={message}  placeholder="Enter message..." onIonChange={e => set_message(e.detail.value!)}></IonTextarea>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonButton expand="block" onClick={handleSupportRequest} ><IonIcon slot="start" ios={paperPlaneOutline} md={paperPlaneOutline}/>Submit Request</IonButton>
              </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(Support);
