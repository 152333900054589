import { createReducer } from "@reduxjs/toolkit";
import * as auth from '../actions/auth'

const user = {
  pk: 0,
  uniqueID:'',
  username: '',
  email: '',
  first_name: '',
  last_name: ''
};

const requestToken = {
  token: '',
};




export const LoginReducer = createReducer(
  { user, requestToken },
  {
    [auth.loginRequest.type]: (state, action) => ({
      ...state,
      payload: { ...action.payload },
    }),
  }
);

export interface Action<T, P> {
    readonly type: T;
    readonly payload?: P;
}

export function createAction<T extends string, P>(type: T, payload: P): Action<T, P> {
    return { type, payload };
}


const initialState = {
  portalLink: null,
  user: null,
  token: '',

};

export function AuthReducer(state = initialState, action : Action<any, any>){
  switch (action.type) {
    case auth.PORTAL_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case auth.PORTAL_SUCCESS: {
      return { ...state, portalLink: action.payload };
    }
    case auth.PORTAL_FAILURE: {
      return { ...state };
    }
    case auth.REGISTER_REQUEST: {
      return { ...state, payload: action.payload };
    }
    case auth.REGISTER_SUCCESS: {
      return { ...state, user: action.payload.user, token: action.payload.token };
    }
    case auth.REGISTER_FAILURE: {
      return { ...state };
    }
    default:
      return { ...state };
  }
};
