import {  IonGrid, useIonToast, IonRow, IonProgressBar, IonTextarea, IonSlides, IonSlide ,IonCol, IonBadge, IonSearchbar, IonNote, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonIcon, IonButton ,IonItem, IonLabel,
  IonCard, IonCardHeader, IonListHeader, IonItemDivider, IonCardSubtitle, IonCardTitle, IonSelect, IonSelectOption,IonCardContent, IonInput, IonToggle, IonRadio, IonCheckbox } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect, useRef } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import { archiveOutline, archiveSharp, optionsOutline, layersOutline, chevronForward, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Audits.css';
import { connect, useDispatch } from 'react-redux';
import { auditGetRequest, createAuditRequest, updateAuditRequest, queueRequest} from '../../actions/audits';
import { useSelector } from 'react-redux'
import {RootState} from '../../reducers/index';
import { CreateAnimation } from "@ionic/react";


const AuditQueue: React.FC = (props) => {

  const { name } = useParams<{ name: string; }>();
  const [audit_name, set_audit_name] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchText1, setSearchText1] = useState('');

  const [audits, set_audits] = useState([{status:""}]);
  const [mainAudits, set_mainAudits] = useState<any[]>([]);
  const [completedAudits, set_completedAudits] = useState<any[]>([]);
  const [activeAuditQueue, set_activeAuditQueue] = useState<any[]>([]);
  const [progressPercent, set_progressPercent] = useState<any>(0);
  const [filterIndex, set_filterIndex] = useState<any>(1);
  const [filteredAudits, set_filteredAudits] = useState<any[]>([]);


  const dispatch = useDispatch();

  const [present, dismiss] = useIonToast();

  const [queueStarted, set_queueStarted] = useState(false);

  //
  const [activeAuditObject, set_activeAuditObject] = useState({procedures_reviewed:'', gaps_found_during_review_of_procedures: '',quality_records_reviewed: '',gaps_found_during_review_of_quality_records: '',
    classification_of_gaps_non_conformance: 1, comments: '',
    status: '' });
  const [procedures_reviewed, set_procedures_reviewed] = useState('');
  const [procedures_gaps, set_procedures_gaps] = useState('');
  const [quality_records_reviewed, set_quality_records_reviewed] = useState('');
  const [quality_records_gaps, set_quality_records_gaps] = useState('');
  const [classification_of_gaps_non_conformance, set_classification_of_gaps_non_conformance] = useState(1);
  const [comments, set_comments] = useState('');

  const selectGlobal = (state: RootState) => state.audits
  const globalState = useSelector(selectGlobal);

  const selectGlobalAppState = (state: RootState) => state.global
  const globalStateAppState = useSelector(selectGlobalAppState);

  const completed = mainAudits.filter((item:any) => item.status === "Done");
  const inProgress = mainAudits.filter((item:any) => item.status === "In Progress");
  const notStarted = mainAudits.filter((item:any) => item.status === "Not Started");

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    effect:'fade',
    grabCursor:true,
  };

  const [swiper, setSwiper] = useState<any>({});

  const init = async function(this: any) {
      setSwiper(await this.getSwiper());
      loadQueueForm(activeAuditQueue[0])
  };

  function renderPercent(num:number) {
    return num.toFixed(2)
  }






  useEffect(() => {

     if(globalState.activeQueue.length > 0){
       var total = globalState.activeQueue.length;
       var numCompleted = 0;
       for(var i=0; i < globalState.activeQueue.length; i++){
         if(globalState.activeQueue[i].status === "Done"){
           numCompleted = numCompleted+1
         }
       }
       var progressPercent = ((numCompleted / total) * 100)
       console.log(progressPercent)
       set_progressPercent(progressPercent)
     }
  })

  useEffect(() => {
     setTimeout(() => dispatch(auditGetRequest()), 10);

  },[])

  useEffect(() => {


     // if(globalStateAppState.showMsg === true && globalStateAppState.alertMsg !== null ){
     //     present(globalStateAppState.alertMsg, 3000)
     // }


     if(globalState.auditList !== null){
       var data = JSON.parse(globalState.auditList)
       var auditsParsed = data.auditStores
       var mainAuds = data.audits
       var tempDoneArr = [];
       var tempNotDoneArr = [];


       //console.log(auditsParsed)
       //console.log(mainAuds)
       for(var i=0; i < mainAuds.length; i++){
         if(mainAuds[i].status === "Done"){
           tempDoneArr.push(mainAuds[i])
         }else{
           tempNotDoneArr.push(mainAuds[i])
         }
       }
       set_completedAudits(tempDoneArr)
       set_mainAudits(tempNotDoneArr)
       set_audits(auditsParsed)
     }

     if(globalState.activeQueue.length > 0){

       const paginatedSet = paginate(globalState.activeQueue, 50, 1)
       const que = paginatedSet.filter((item:any) => item.status === "Not Started" || item.status === "In Progress");
       if(que.length > 0){
         set_activeAuditQueue(que)
       }else{
         set_activeAuditQueue(paginatedSet)
       }

       set_queueStarted(true)

     }
     //set_audits(globalState.auditList)

  },[globalState.auditList, globalState.activeQueue, globalStateAppState.showMsg, globalStateAppState.alertMsg])

    function handleAuditCreate(e:any) {
         //dispatch(createAuditRequest({regulation:audits, audit_name:audit_name}))
    }

    function handleNextButton(e:any) {
      swiper.slideNext();
      dispatch(updateAuditRequest({uniqueID:e.uniqueID, procedures_reviewed: procedures_reviewed, classification_of_gaps_non_conformance: classification_of_gaps_non_conformance, quality_records_reviewed: quality_records_reviewed, gaps_found_during_review_of_quality_records: quality_records_gaps,gaps_found_during_review_of_procedures:procedures_gaps,comments:comments, status: "In Progress" }))

    }

    function handlePrevButton(e:any) {
      swiper.slidePrev();
    }


    function handleAuditSave(e:any) {
      //console.log(e.id)
      dispatch(updateAuditRequest({uniqueID:e.uniqueID, procedures_reviewed: procedures_reviewed, classification_of_gaps_non_conformance: classification_of_gaps_non_conformance, quality_records_reviewed: quality_records_reviewed, gaps_found_during_review_of_quality_records: quality_records_gaps,gaps_found_during_review_of_procedures:procedures_gaps,comments:comments, status: "In Progress" }))
    }

    function handleAuditMarkDone(e:any) {
      dispatch(updateAuditRequest({uniqueID:e.uniqueID, procedures_reviewed: procedures_reviewed, gaps_found_during_review_of_procedures: procedures_gaps,quality_records_reviewed: quality_records_reviewed,gaps_found_during_review_of_quality_records: quality_records_gaps,
        classification_of_gaps_non_conformance: classification_of_gaps_non_conformance, comments: comments,
        status: "Done" }))

        //setTimeout(() => swiper.slideNext(), 1000);

      //dispatch(updateAuditRequest({uniqueID:e.uniqueID, status: "Done" }))
    }


    function handleSlideChange(e:any) {
      e.preventDefault()

      var index = swiper.activeIndex
      //console.log(index)
      //
      if(activeAuditQueue.length > 0){

        if(activeAuditQueue[index].classification_of_gaps_non_conformance !==null){
          set_classification_of_gaps_non_conformance(parseInt(activeAuditQueue[index].classification_of_gaps_non_conformance.toString()))
        }else{
          set_classification_of_gaps_non_conformance(1)
        }

        set_activeAuditObject(activeAuditQueue[index])
        //console.log(activeAuditObject)
        set_procedures_reviewed(activeAuditQueue[index].procedures_reviewed || '')
        set_procedures_gaps(activeAuditQueue[index].gaps_found_during_review_of_procedures || '')
        set_quality_records_reviewed(activeAuditQueue[index].quality_records_reviewed || '')
        set_quality_records_gaps(activeAuditQueue[index].gaps_found_during_review_of_quality_records || '')
        set_comments(activeAuditQueue[index].comments || '')
      }


    }

    function loadQueueForm(auditStore:any) {

      if(auditStore.classification_of_gaps_non_conformance !==null){
        set_classification_of_gaps_non_conformance(parseInt(auditStore.classification_of_gaps_non_conformance.toString()))
      }else{
        set_classification_of_gaps_non_conformance(1)
      }
          //
      set_activeAuditObject(auditStore)
      //console.log(auditStore)
      set_procedures_reviewed(auditStore.procedures_reviewed || '')
      set_procedures_gaps(auditStore.gaps_found_during_review_of_procedures || '')
      set_quality_records_reviewed(auditStore.quality_records_reviewed || '')
      set_quality_records_gaps(auditStore.gaps_found_during_review_of_quality_records || '')
      set_comments(auditStore.comments || '')

    }

    function toggleQueueScreens() {
      set_queueStarted(!queueStarted)
    }

    function renderStatus(status:string) {
      if(status === "Done"){
        return(<IonBadge color="success">{status}</IonBadge>);
      }else if(status === "In Progress"){
        return(<IonBadge color="warning">{status}</IonBadge>)
      }else{
        return(<IonBadge color="light">{status}</IonBadge>)
      }
    }




    function getUniqueListBy(arr:any, key:any) {
      return [...new Map(arr.map((item:any) => [item[key], item])).values()]
    }

    const RenderQueueItem: React.FC<{item:any, index:number}> = ({children, item, index}) => {
      //console.log(audits)

      return(
        <IonSlide >
          <IonCol>
            <IonRow>
              <IonCol>
                <IonLabel>Total Audit Completion: <b>{renderPercent(progressPercent)} %</b></IonLabel>
                <br />
                <IonProgressBar value={progressPercent/100} ></IonProgressBar>
              </IonCol>
            </IonRow>
            <br />
            <IonRow>
              <IonCol>
                <IonTitle><b>{item.audit_name}</b></IonTitle>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Case ID: <b>{item.uniqueID}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {renderStatus(item.status)}
              </IonCol>
            </IonRow>
            <br />
            <IonRow>
              <IonCol>
                <IonLabel>Regulation: <b>{item.regulation}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Section: <b>{item.section}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Topic: <b>{item.topic}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Details: <b>{item.details}</b></IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTextarea placeholder="Procedures Reviewed..." value={procedures_reviewed} onIonChange={e => set_procedures_reviewed(e.detail.value!)}></IonTextarea>
                <IonTextarea placeholder="Procedure(s ) Gaps Review..." value={procedures_gaps} onIonChange={e => set_procedures_gaps(e.detail.value!)}></IonTextarea>
                <IonTextarea placeholder="Quality Record(s) Reviewed..." value={quality_records_reviewed} onIonChange={e => set_quality_records_reviewed(e.detail.value!)}></IonTextarea>
                <IonTextarea placeholder="Quality Record(s) Gaps..." value={quality_records_gaps} onIonChange={e => set_quality_records_gaps(e.detail.value!)}></IonTextarea>
                <IonTextarea placeholder="Comments..." value={comments} onIonChange={e => set_comments(e.detail.value!)}></IonTextarea>
                <br />
                <IonLabel>Classification of Gap(s) found:</IonLabel>
                <br />
                <IonSelect value={classification_of_gaps_non_conformance} onIonChange={e => {set_classification_of_gaps_non_conformance(e.detail.value!)}} placeholder="Select a classification...">
                  <IonSelectOption value={1}>1 - N/A, Meets Regulation</IonSelectOption>
                  <IonSelectOption value={2}>2 - Recommendation</IonSelectOption>
                  <IonSelectOption value={3}>3 - Minor Finding</IonSelectOption>
                  <IonSelectOption value={4}>4 - Major Finding</IonSelectOption>
                  <IonSelectOption value={5}>5 - Critical Finding</IonSelectOption>
                </IonSelect>
                <br />
                <IonButton color="dark" size="large" onClick={ () => handlePrevButton(item)}>Prev</IonButton>
                <IonButton color="dark" size="large" onClick={ () => handleNextButton(item)}>Next</IonButton>
                <IonButton color="dark" size="large" onClick={ () => handleAuditSave(item)}>Save</IonButton>
                <IonButton color="success" size="large" onClick={ () => handleAuditMarkDone(item)}>Mark Complete & Save</IonButton>
                <IonButton size="large" routerLink={'/queue'}>End Queue</IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>Page: <b>{index+1}</b></IonLabel>
                <br />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonSlide>
      )

    }

    function renderQueueItem(item: any, index:number) {
        return(
            <IonSlide >
              <IonCol>
                <IonRow>
                  <IonCol>
                    <IonLabel>Total Audit Completion: <b>{renderPercent(progressPercent)}%</b></IonLabel>
                    <br />
                    <IonProgressBar value={progressPercent/100} ></IonProgressBar>
                  </IonCol>
                </IonRow>
                <br />
                <IonRow>
                  <IonCol>
                    <IonTitle><b>{item.audit_name}</b></IonTitle>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Case ID: <b>{item.uniqueID}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {renderStatus(item.status)}
                  </IonCol>
                </IonRow>
                <br />
                <IonRow>
                  <IonCol>
                    <IonLabel>Regulation: <b>{item.regulation}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Section: <b>{item.section}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Topic: <b>{item.topic}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Details: <b>{item.details}</b></IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonTextarea placeholder="Procedures Reviewed..." value={procedures_reviewed} onIonChange={e => set_procedures_reviewed(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Procedure(s ) Gaps..." value={procedures_gaps} onIonChange={e => set_procedures_gaps(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Quality Record(s) Reviewed..." value={quality_records_reviewed} onIonChange={e => set_quality_records_reviewed(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Quality Record(s) Gaps..." value={quality_records_gaps} onIonChange={e => set_quality_records_gaps(e.detail.value!)}></IonTextarea>
                    <IonTextarea placeholder="Comments..." value={comments} onIonChange={e => set_comments(e.detail.value!)}></IonTextarea>
                    <br />
                    <IonLabel>Classification of Gap(s) found:</IonLabel>
                    <br />
                    <IonSelect value={classification_of_gaps_non_conformance} onIonChange={e => {set_classification_of_gaps_non_conformance(e.detail.value!)}} placeholder="Select a classification...">
                      <IonSelectOption value={1}>1 - N/A, Meets Regulation</IonSelectOption>
                      <IonSelectOption value={2}>2 - Recommendation</IonSelectOption>
                      <IonSelectOption value={3}>3 - Minor Finding</IonSelectOption>
                      <IonSelectOption value={4}>4 - Major Finding</IonSelectOption>
                      <IonSelectOption value={5}>5 - Critical Finding</IonSelectOption>
                    </IonSelect>
                    <br />
                    <IonButton color="primary" size="small" onClick={ () => handlePrevButton(item)}>Prev</IonButton>
                    <IonButton color="warning" size="small" onClick={ () => handleAuditSave(item)}>Save</IonButton>
                    <IonButton color="success" size="small" onClick={ () => handleAuditMarkDone(item)}>Complete Audit</IonButton>
                    <IonButton color="primary" size="small" onClick={ () => handleNextButton(item)}>Next</IonButton>
                    <IonButton color="danger" size="small" routerLink={'/queue'}>Exit Queue</IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Page: <b>{index+1}</b></IonLabel>
                    <br />
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonSlide>
          );
    }

    function handleSlideReachedEnd(e:any) {

      //console.log(filterIndex)
      //swiper.slideTo(0)

      const newIdx = filterIndex+1
      set_filterIndex(newIdx)

      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    }

    function paginate(array:any, page_size:number, page_number:number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    }


    function renderQueueSlides(audits: any, filterIndex:any) {
      var data = audits
      //var filteredSets = audits.length / 50

      const sortedAudits = data.sort(function(a:any, b:any){
          return a.id - b.id;
      });


      var renderList = [];
      for(var i=0;i< sortedAudits.length; i++){
        renderList.push(renderQueueItem(sortedAudits[i], i))

      }
      //const mapComponents = data.map((item:any,i:number) => renderQueueItem(item, i));

      //console.log(mapComponents)

      return renderList
    }

    function handleItemDetailLink(audList:any,item:any) {
      //console.log(item)
      setTimeout(() => dispatch(queueRequest({uniqueID:item.uniqueID})), 10);

      // var tempArr = [];
      // if(audList.length > 0){
      //   for(var i=0;i < audList.length; i++){
      //     if(audList[i].audit === item.id){
      //       tempArr.push(audList[i])
      //     }
      //   }
      //   set_activeAuditQueue(tempArr)
      //   toggleQueueScreens()
      //   loadQueueForm(tempArr[0])
      //
      // }
    }

    function renderTableItem(item: any) {
        return(
          <IonItem button onClick={() => {handleItemDetailLink(audits,item)}} detail={true} >
            <IonLabel>{item.name}</IonLabel>
            {renderStatus(item.status)}
          </IonItem>
          );
    }

  const RenderTable: React.FC<{audits:any, searchText:string}> = ({children, audits, searchText}) => {
      //console.log(audits)
      var data = audits
      var renderList = [];

      //const [filterText, set_filterText] = React.useState('');

      let filteredItems = data.filter((item:any) => item.name && item.name.toLowerCase().includes(searchText.toLowerCase()));

      filteredItems = filteredItems.sort(function(a:any, b:any){
          return b.id - a.id;
      });

      //console.log(filteredItems)
      for(var i=0;i< filteredItems.length; i++){
        renderList.push(renderTableItem(filteredItems[i]))
      }
      return(
        <IonList>
          {renderList}
        </IonList>
      )

    }



  // var fetch = require('superfetch');
  //
  // fetch.get.option({ encoding: 'utf8', headers: {
  //   'User-Agent': 'request',
  //   'Authorization':"Basic Y2NyOmFkbWlu",
  //   'Content-Type': "application/json",
  //   'Accept': 'application/json'
  //   },
  // })('http://127.0.0.1:8000/audit-types/').then(function (body: any) {
  //   console.log(body)
  //   // when the status code begins with "2", the response body will be returned.
  // }).catch(function (response : any) {
  //   // otherwise, the whole response(including headers) is returned.
  // });


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Start / Resume Audits</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Start / Resume Audits</IonTitle>
          </IonToolbar>
        </IonHeader>
        {queueStarted
          ?(<>{activeAuditQueue.length > 0
              ? (
                <IonSlides key={activeAuditQueue.length} onIonSlidesDidLoad={init} pager={true} options={slideOpts} onIonSlideDidChange={(e:any)=> handleSlideChange(e)} onIonSlideReachEnd={(e:any)=>handleSlideReachedEnd(e)}>
                    {renderQueueSlides(activeAuditQueue, filterIndex)}
                </IonSlides>
              )
              : <IonTitle size="large">No Audits Found</IonTitle>}
            </>)
          :(<>
            <IonGrid>
              <IonRow>
                <IonCol size={'4'}>
                  <IonIcon class="audit-icon" ios={layersOutline} md={layersOutline} />
                </IonCol>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonLabel><b>Click on an existing audit to start a work queue.</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Number of Audits Completed: <b>{completed.length}</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Number of Audits In Progress: <b>{inProgress.length}</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonLabel>Number of Audits Not Started: <b>{notStarted.length}</b></IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton color="" size="large" routerLink={'/create'}>Create A New Audit</IonButton>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Audits - Not Started / In Progress</IonCardTitle>
                <IonSearchbar value={searchText1} onIonChange={e => setSearchText1(e.detail.value!)}></IonSearchbar>
              </IonCardHeader>

              <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonLabel>Audit Name</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>Status</IonLabel>
                  </IonCol>
                </IonRow>
                {audits.length > 0
                  ? (<RenderTable audits={mainAudits} searchText={searchText1}/>)
                  : (<IonRow>
                     <IonCol>
                       <IonLabel>No Audit Available</IonLabel>
                     </IonCol>
                    </IonRow>)

                }



              </IonGrid>

              </IonCardContent>
            </IonCard>
            </>)
        }
      </IonContent>
    </IonPage>
  );
};

// export default Audits;

export default connect((props: any) => ({
     login: props.auth
}))(AuditQueue);
